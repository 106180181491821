import React from 'react';
import {Button} from "reactstrap";
import classnames from "classnames";
import {useSelector} from "react-redux";

import {callsSelectors} from "../../../../../redux/toolkit/calls/selectors";
import css from '../XsiForm.module.scss';

const XsiFormSubscriptionListHeader = props => {
    const {onGetSubscribes, onUnsubscribe} = props;
    const {loading, subscriptions} = useSelector(callsSelectors.callsXsiEvents);

    return (
        <div className={css.header}>
            <Button
                className={`btn-multiple-state  ${css.subscriptions}  ${classnames({
                    'show-spinner': loading,
                    'show-success': !loading && subscriptions.length,
                    'show-fail': !loading && !subscriptions.length,

                })}`}
                color="primary"
                onClick={onGetSubscribes}
                disabled={loading}
            >
                <div className={css.btnTextWrapper}>
                    Проверить подписки

                    <div className={css.spinnerWrapper}>
                        <div className={`spinner d-inline-block ${css.spinnerWrapper}`}>
                            <span className="bounce1"/>
                            <span className="bounce2"/>
                            <span className="bounce3"/>
                        </div>
                        <span className="icon success">
                            <i className="simple-icon-check"/>
                        </span>
                        <span className="icon fail">
                            <i className="simple-icon-minus"/>
                        </span>
                    </div>

                </div>
            </Button>
            <button
                onClick={() => onUnsubscribe('')}
                type="button"
                className={`btn btn-secondary ml-3`}
                disabled={loading}
            >
                Удалить все подписки
            </button>
        </div>
    )
}
export default XsiFormSubscriptionListHeader;