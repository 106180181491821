/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch } from 'react-redux';
import TextareaAutosize from "react-textarea-autosize";
import {FileDrop} from "react-file-drop";
import {send} from "@giantmachines/redux-websocket";
import { Loader1 } from '../../svg/Loader';
import {fetchManagers} from "../../../api/query/directory";
import {getEndWord, getStartWord, getWord} from "../../text/GetWord";
import UniqID from "../../common/UniqID";
import ChatTypes from "./ChatTypes/ChatTypes";
import Messages from "./Messages";
import SearchUser from "./SearchUser";
import {addComment, getChatId, getNic, loadComments, loadCountComments} from "./helper";
import Callback from "./Callback";
import {getImLooking} from "../../../helpers/Utils";
import {DesktopContext} from "../../../utils/hoc/withContext";
import {commentsViewing} from "../../../redux/comment/actions";
import {appActions} from "../../../redux/toolkit/app/slice";
import ResizableBox from './ResizableBox';
import css from "./Comments.module.scss";

const Comments = (props) => {
    const {articleId, chatTypes = [], callbackReload = () => null, changeChat = false} = props;
    const dispatch = useDispatch();
    const desktop = useContext(DesktopContext)

    const [comments, setComments] = useState([]);
    const [commentsMeta, setCommentsMeta] = useState({totalCount: 0, pageCount: 0, currentPage: 0, perPage: 10});
    const [activeChat, setActiveChat] = useState((desktop || !changeChat) ? chatTypes[0] : null);

    const [activeStyle, setActiveStyle] = useState(null)

    const [executionQuery, setExecutionQuery] = useState(false);

    const buttonAdd = useRef()

    const getUniqId = UniqID('comment_container_');
    const [inputText, setInputText] = useState('');
    const [files, setFiles] = useState([]);
    const [dragged, setDragged] = useState(false);
    const [openResultUsers, setOpenResultUsers] = useState(false);
    const [usersFound, setUsersFound] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [countNewMessage, setCountNewMessage] = useState({});

    const refFieldInput = useRef(null);
    const refSelectResultUser = useRef(null);
    const inputTextRef = useRef();
    const scrollBar = useRef(null);

    const [selectedSearchIndex, setSelectedSearchIndex] = useState(null);

    const handleActiveChat = (el) => {
        setActiveChat(el)
        setComments([])
    }

    const fetchCount = async () => {
        if (chatTypes.length < 2) return false;
        const params = {
            types: chatTypes.filter(x => getChatId(x) !== getChatId(activeChat)),
            article_id: articleId,
        }
        const {type: resultType, data: resultData} = await loadCountComments(params);
        if (resultType === 'success') {
            const dataCount = {};
            resultData.forEach(x => {
                const chatId = getChatId(x);
                dataCount[chatId] = x.count_new;
            })
            setCountNewMessage((oldValues) => {
                return {...oldValues, ...dataCount};
            });
        }
        return true;
    }

    const changeCountMessage = () => {
        const currentChat = getChatId(activeChat);
        setCountNewMessage((oldValue) => {
            return {...oldValue, [currentChat]: 0};
        });
    }

    const callbackFetchList = data => {
        const {items = [], _meta} = data || {}
        const allComments = [...comments, ...items]
        const uniqueComments = [...new Map(allComments.map(comment => [comment.id, comment])).values()]
        const sortedComments = uniqueComments.sort((a, b) => new Date(a.created) - new Date(b.created))
        setComments(sortedComments)
        if (_meta) setCommentsMeta(_meta)
        const commentIdList = items.map(x => x.id)
        dispatch(commentsViewing({id: commentIdList}))
        callbackReload(commentIdList)
        changeCountMessage()
    }

    const fetchPrevious = async () => {
        if (commentsMeta.currentPage >= commentsMeta.pageCount) return
        const previousScrollHeight = scrollBar.current.getScrollHeight()
        await fetchMessages()
        if (!scrollBar.current) return
        const newScrollHeight = scrollBar.current.getScrollHeight()
        const scrollTop = newScrollHeight - previousScrollHeight
        scrollBar.current.scrollTop(scrollTop)
    }
    const fetchMessages = async (isUpdate = false) => {
        if (!activeChat) return
        const readId = activeChat.readId ?? []
        const entityId = activeChat.entityId ?? null
        const params = {
            type_id: readId,
            article_id: articleId,
            entity_id: entityId,
            expand: 'userData, userData.emails, userData.avatarData, userData.state, files',
            sort: '-created',
            page: isUpdate ? 1 : commentsMeta.currentPage + 1,
            'per-page': commentsMeta.perPage,
        }
        setExecutionQuery(true)
        const {type: resultType, data: resultData} = await loadComments(params)
        setExecutionQuery(false)
        if (resultType !== 'success') {
            return dispatch(appActions.setNotification({type: 'error', message: resultData}))
        }
        if (isUpdate) delete resultData._meta
        callbackFetchList(resultData)
        if (!scrollBar.current) return
        if (!comments.length || isUpdate) scrollBar.current.scrollToBottom()
    }

    const searchUsers = (text) => {
        const prepareParams = {
            s: text,
            expand: 'avatarData, emails'
        };
        fetchManagers(prepareParams)
            .then((response) => {
                setUsersFound(response.data);
            })
            .catch(() => {})
    };

    const setNewInputText = (nic) => {
        const inputTextElement = inputTextRef.current;
        const positionStart = inputTextElement.selectionStart;
        if (positionStart !== null) {
            const start = getStartWord(inputText, positionStart);
            const end = getEndWord(inputText, positionStart);
            let newText = `${inputText.substring(0, start)} @${nic}, ${inputText.substring(end, inputText.length)}`;
            newText = newText.replace(/\s+/g, ' ').trimLeft();
            setInputText(newText);
        }
        inputTextRef.current.focus();
    };

    const onSelectedUser = (id) => {
        const selectedUser = usersFound.find(x => Number.parseInt(x.id, 10) === Number.parseInt(id, 10));
        if (typeof selectedUser === 'undefined') {
            return false;
        }
        setSelectedUsers([...selectedUsers, selectedUser]);
        const nic = getNic(selectedUser);
        setNewInputText(nic);
        setOpenResultUsers(false);
        return true;
    };

    const onChangeInput = (event) => {
        const {value, selectionStart} = event.target;
        setInputText(value);
        if (value.indexOf('@') > -1) {
            const currentWord = getWord(value, selectionStart).trim();
            if (currentWord.indexOf('@') > -1) {
                const newValue = currentWord.replace('@', '');
                searchUsers(newValue);
                setOpenResultUsers(true);
            } else {
                setOpenResultUsers(false);
            }
        }
    };

    const onKeyDownSelectedUser = (event) => {
        const code = Number.parseInt(event.keyCode, 10);
        const elementList = refSelectResultUser.current;
        let newIndex = null;
        if (code === 38) {
            if (selectedSearchIndex !== null) {
                if (typeof usersFound[selectedSearchIndex-1] !== 'undefined') {
                    newIndex = selectedSearchIndex-1;
                } else {
                    newIndex = usersFound.length-1;
                }
            } else {
                newIndex = usersFound.length-1;
            }
        } else if (code === 40) {
            if (selectedSearchIndex !== null) {
                if (typeof usersFound[selectedSearchIndex+1] !== 'undefined') {
                    newIndex = selectedSearchIndex+1;
                } else {
                    newIndex = 0;
                }
            } else {
                newIndex = 0;
            }
        }
        setUsersFound(usersFound.map((x, index) => {
            // eslint-disable-next-line no-param-reassign
            x.selected = index === newIndex;
            return x;
        }));
        setSelectedSearchIndex(newIndex);
        if (newIndex) {
            const elementNew = elementList.querySelector(`.search-item[data-index="${newIndex}"]`);
            elementNew.scrollIntoView(false);
        }
    };

    const onKeyDownInputComment = (event) => {
        const code = Number.parseInt(event.keyCode, 10);
        if ((code === 38 || code === 40) && usersFound.length) {
            event.preventDefault();
            onKeyDownSelectedUser(event);
        }
    };

    const disabledSubmit = useMemo(() => {
        if (!activeChat) return false
        const writeId = activeChat.writeId ?? null;
        const readId = activeChat.readId ?? [];
        if (!readId.length || !writeId || !articleId) return true;
        if (executionQuery) return true;
        return false;
    }, [executionQuery, activeChat]);

    const createComment = async () => {
        if (disabledSubmit) return false;
        if (!inputText && !files.length) return false;
        const entityId = activeChat.entityId ?? null;
        const formData = new FormData();
        formData.append('entity_id', entityId);
        formData.append('type_id', activeChat.writeId);
        formData.append('article_id', articleId);
        formData.append('message', inputText);
        if (files.length) {
            Array.from(files).forEach((file) => {
                formData.append('files[]', file);
            });
        }
        setExecutionQuery(true);
        const {type: resultType, data: resultData} = await addComment(formData);
        setExecutionQuery(false);
        if (resultType === 'success') {
            // fetchMessages(true);
            setInputText('');
            setFiles([]);
            return true;
        }
        dispatch(appActions.setNotification({type: 'error', message: resultData}));
        return true;
    };
    const handleChatInputPress = (e) => {
        if (e.key === 'Enter' && openResultUsers && selectedSearchIndex !== null) {
            e.preventDefault();
            const getSelectedUser = usersFound[selectedSearchIndex];
            if (typeof getSelectedUser !== 'undefined') {
                onSelectedUser(getSelectedUser.id);
            }
            return true;
        }
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            createComment();
        }
        return  true;
    };
    const onChangeFiles = (event) => {
        const item = event.target;
        setFiles(item.files);
        inputTextRef.current.focus();
        return true;
    };

    const onPasteEvent = (event) => {
        const filesClipBoard = event.clipboardData.files;
        if (filesClipBoard.length) {
            setFiles([...files, ...filesClipBoard]);
        }
    };

    const handleDrop = (fileList) => {
        if (fileList.length) {
            setFiles([...files, ...fileList]);
        }
        setDragged(false);
    };

    const sendToUser = (nic) => {
        setNewInputText(nic);
    };

    const imLookingChat = (status) => {
        dispatch(send(getImLooking(7, articleId, status)));
    }

    const imLookingActiveChat = (status) => {
        const chatId = `${articleId}_${getChatId(activeChat)}`;
        dispatch(send(getImLooking(7, chatId, status)));
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                if (event.target.classList.value.includes('MuiDialog-container MuiDialog-scrollPaper')) {
                    buttonAdd.current.click()
                }
            }
        };

        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [buttonAdd.current]);

    useEffect(() => {
        const onClickOutside = (e) => {
            const inputElement = refFieldInput.current;
            const resultElement = refSelectResultUser.current;

            if (inputElement && !inputElement.contains(e.target) && resultElement && !resultElement.contains(e.target)) {
                setOpenResultUsers(false);
            }
        };
        document.addEventListener('mousedown', onClickOutside);
        return () => document.removeEventListener('mousedown', onClickOutside);
    }, []);

    useEffect(() => {
        if (!openResultUsers) {
            setUsersFound([]);
        }
    }, [openResultUsers]);

    useEffect(() => {
        if (!usersFound.length) {
            setSelectedSearchIndex(null);
        }
    }, [usersFound]);

    useEffect(() => {
        fetchMessages();
    }, [activeChat, articleId]);

    useEffect(() => {
        fetchCount();
    }, [chatTypes, articleId]);

    useEffect(() => {
        imLookingActiveChat(1);
        return () => {
            imLookingActiveChat(0);
        }
    }, [activeChat]);

    useEffect(() => {
        imLookingChat(1);
        return () => {
            imLookingChat(2);
            setComments([]);
        }
    }, []);

    if (!chatTypes.length) {
        return <>Чат не задан</>
    }

    return (
        <>
            <div className={`${css.comments} ${css.windowFiles} ${dragged ? css.dragged : ''}`}>
                <FileDrop
                    onDragOver={() => setDragged(true)}
                    onDragLeave={() => setDragged(false)}
                    onDrop={handleDrop}
                >
                    <ResizableBox>
                        <div className={`${css.contentContent} ${activeStyle && css.commentsContentProvider}`}>
                            {((!activeChat && !desktop) || (desktop)) &&
                                <ChatTypes
                                    types={chatTypes}
                                    activeChat={activeChat}
                                    setActiveChat={handleActiveChat}
                                    countNewMessage={countNewMessage}
                                    setActiveStyle={setActiveStyle}
                                />
                            }
                            {(activeChat || !changeChat || desktop) &&
                                <div className={`${css.messages} ${activeStyle && css.messagesProdived}`}>
                                    <Messages
                                        {...{
                                            comments,
                                            scrollBar,
                                            sendToUser ,
                                            activeChat,
                                            types: chatTypes,
                                            setActiveChat,
                                            changeChat,
                                            setActiveStyle,
                                            activeStyle,
                                            executionQuery,
                                            fetchPrevious,
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </ResizableBox>
                    {(activeChat || !changeChat || desktop) &&
                        <div className={`${css.commentsControl} ${activeStyle && css.commentsControlProvider}`}>
                            <div className="row">
                                <div ref={refFieldInput} className="col position-relative pr-0">
                                    <TextareaAutosize
                                        ref={inputTextRef}
                                        onChange={onChangeInput}
                                        value={inputText}
                                        minRows={1}
                                        maxRows={16}
                                        className={`form-control ${css.messageText}`}
                                        placeholder="Введите текст..."
                                        onKeyPress={handleChatInputPress}
                                        style={{resize: 'none'}}
                                        id="modal-create-task__comment"
                                        onPaste={onPasteEvent}
                                        onKeyDown={onKeyDownInputComment}
                                    />
                                    <label className={`btn ${css.attachment}`} htmlFor={`${getUniqId}_attachment`}>
                                        {!!files.length && (
                                            <span className={css.countFiles}>{files.length}</span>
                                        )}
                                        <i className="icon-attachment" />
                                        <input
                                            id={`${getUniqId}_attachment`}
                                            type="file"
                                            name="files[]"
                                            multiple
                                            className="form-control d-none"
                                            onChange={onChangeFiles}
                                            max={9}
                                        />
                                    </label>
                                    {executionQuery && (
                                        <div className={css.loader}>
                                            <Loader1 />
                                        </div>
                                    )}
                                </div>
                                <div className="col-auto pl-0">
                                    <button
                                        disabled={disabledSubmit}
                                        type="button"
                                        onClick={createComment}
                                        ref={buttonAdd}
                                        className={`btn btn-primary ${css.submitComment} ${activeStyle && css.submitCommentProvider}`}
                                    >
                                        <i className="icon-send" />
                                    </button>
                                </div>
                            </div>
                            {openResultUsers && (
                                <SearchUser
                                    ref={refSelectResultUser}
                                    usersFound={usersFound}
                                    onSelectedUser={onSelectedUser}
                                />
                            )}
                        </div>
                    }
                </FileDrop>
            </div>
            <Callback
                articleId={articleId}
                typesId={activeChat?.readId}
                reload={() => fetchMessages(true)}
                reloadCount={fetchCount}
            />
        </>
    );
};
export default Comments;
