import {Fab, Fade} from '@mui/material'
import {KeyboardArrowDown} from '@mui/icons-material'

const GoBottomButton = ({show, onClick}) => (
    <Fade in={show}>
        <Fab
            color="primary"
            size="small"
            aria-label="scroll-to-bottom"
            onClick={onClick}
            sx={{position: 'absolute', bottom: 16, right: 16}}>
            <KeyboardArrowDown />
        </Fab>
    </Fade>
)

export default GoBottomButton
