import React, {useContext, useEffect, useMemo} from 'react';
import FormError  from '../../../../components/validate/FormError';
import {FormGroup} from "reactstrap";
import {Grid} from "@mui/material";
import Brands from "../fields/Brands";
import {ModalCreateTaskContext} from "../index";
import OnlyProfile from "../fields/OnlyProfile";
import {usedBrands} from "../../../../constants/defaultValues";
import {getParentType} from "../helper";

export const validate = (data) => {
    const errors = {};
    if (data?.client?.segment_income?.id == 3) {
        errors.client = 'Нельзя создать такую задачу для контрагента типа "C"'
    }
    return errors;
}

const Template52 = () => {
    const {data, setData, errors, onChangeList, taskTypes, changedData} = useContext(ModalCreateTaskContext);
    const {onChangeBrand, onChangeOnlyProfile} = onChangeList;

    const isDisabledBrand = true;

    const brandValue = data.brand;

    const disabledOnlyProfile = useMemo(() => {
        const activeStatus = [2,3,4];
        const clientStatusId = Number(data.client?.orderData?.status_id ?? 0);
        const clientSegmentIncomeId = Number(data.client?.segment_income?.id ?? 0);

        const condition1 = clientStatusId && !activeStatus.includes(clientStatusId) && ![1,2].includes(clientSegmentIncomeId);
        const condition2 = data.id && !changedData.taskTypeTemplate;
        return condition1 || condition2;
    }, [data.client, data.id, changedData.taskTypeTemplate]);

    useEffect(() => {
        if (changedData.taskTypeTemplate) {
            onChangeOnlyProfile(true);
        }
    }, [disabledOnlyProfile, changedData.taskTypeTemplate]);

    useEffect(() => {
        if (!brandValue && (!data.id || changedData.taskTypeTemplate)) {
            onChangeBrand(usedBrands.group);
        }
        return () => {
            onChangeOnlyProfile(false);
        }
    }, []);

    return (
        <>
            <Grid item xs={2}>
                <FormGroup>
                    <Brands
                        value={brandValue}
                        setValue={onChangeBrand}
                        isDisabled={isDisabledBrand}
                    />
                    <FormError errors={errors} name="brand" />
                </FormGroup>
            </Grid>
            <Grid item xs={2}>
                <FormGroup>
                    <OnlyProfile
                        value={data.onlyProfile}
                        setValue={onChangeOnlyProfile}
                        isDisabled={disabledOnlyProfile}
                    />
                    <FormError errors={errors} name="onlyProfile" />
                </FormGroup>
            </Grid>
        </>
    );
};
export default Template52;
