import { SendGet } from '../handler/sendGet';
import {sendPost} from "../handler/sendPost";

// eslint-disable-next-line import/prefer-default-export
export const fetchCallsHistory = (params) => {
  return SendGet(`calls`, params);
};
export const xsiEventsSubscribe = (params) => {
  return sendPost(`calls/xsi-events-subscribe`, params);
};
export const xsiEventsUnsubscribe = (params) => {
  return sendPost(`calls/xsi-events-unsubscribe`, params);
};
export const xsiEventsGetSubscriptions = (params) => {
  return SendGet(`calls/xsi-events-get-subscription-list`, params);
};
export const xsiEventsGetAbonentsSubscribedTo  = (params) => {
  return SendGet(`calls/xsi-events-get-abonents-subscribed-to`, params);
};