import {put, call, takeEvery} from 'redux-saga/effects'

import {callsActions} from "./slice";
import ErrorResponse from "../../../components/common/ErrorResponse";
import {
    fetchCallsHistory,
    xsiEventsSubscribe,
    xsiEventsUnsubscribe,
    xsiEventsGetSubscriptions,
    xsiEventsGetAbonentsSubscribedTo
} from "../../../api/query/calls";
import {appActions} from "../app/slice";


function* fetchCallHistory({payload}) {
    try {
        const getData = yield call(fetchCallsHistory, payload);
        const codeResponse = Number.parseInt(getData.status, 10);

        if (codeResponse === 200) {
            yield put(callsActions.setCallHistory(getData.data));
        }
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);

        yield put(callsActions.setCallHistory(null));
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
    }
}

function* fetchXsiEventsSubscribe({ payload }) {
    try {
        const getData = yield call(xsiEventsSubscribe, payload);
        const codeResponse = Number.parseInt(getData.status, 10);

        if (codeResponse === 200) {
            const getDataSubs = yield call(xsiEventsGetSubscriptions, []);
            const codeResponseSubs = Number.parseInt(getDataSubs.status, 10);

            if (codeResponseSubs === 200) {
                yield put(callsActions.setXsiEventsSubscriptions(getDataSubs.data));

                const getDataAbonents = yield call(xsiEventsGetAbonentsSubscribedTo, payload);
                const codeResponseAbonents = Number.parseInt(getDataAbonents.status, 10);

                if (codeResponseAbonents === 200) {
                    yield put(callsActions.setXsiEventsAbonentsList(getDataAbonents.data));
                } else {
                    yield put(callsActions.setXsiEventsErrors());
                }
            } else {
                yield put(callsActions.setXsiEventsSubscriptions(null));
            }
        }
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(callsActions.setXsiEventsErrors());
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
    }
}

function* fetchXsiEventsUnsubscribe({ payload }) {
    try {
        const getData = yield call(xsiEventsUnsubscribe, payload);
        const codeResponse = Number.parseInt(getData.status, 10);

        if (codeResponse === 200) {
            const getDataSubs = yield call(xsiEventsGetSubscriptions, []);
            const codeResponseSubs = Number.parseInt(getDataSubs.status, 10);

            if (codeResponseSubs === 200) {
                yield put(callsActions.setXsiEventsSubscriptions(getDataSubs.data));
            } else {
                yield put(callsActions.setXsiEventsSubscriptions(null));
                yield put(appActions.setNotification({type: 'error', message: 'Ошибка: нет подписок!'}));
            }

            const getDataAbonents = yield call(xsiEventsGetAbonentsSubscribedTo, payload);
            const codeResponseAbonents = Number.parseInt(getDataAbonents.status, 10);

            if (codeResponseAbonents === 200) {
                yield put(callsActions.setXsiEventsAbonentsList(getDataAbonents.data));
            } else {
                yield put(appActions.setNotification({
                    type: 'error',
                    message: 'Ошибка при получении списка абонентов: ' + codeResponseAbonents
                }));
                yield put(callsActions.setXsiEventsAbonentsList([]));
            }
        }
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(callsActions.setXsiEventsErrors());
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
    }
}

function* fetchXsiEventsGetSubscriptions({ payload }) {
    try {
        const getData = yield call(xsiEventsGetSubscriptions, payload);
        const codeResponse = Number.parseInt(getData.status, 10);

        if (codeResponse === 200) {
            yield put(callsActions.setXsiEventsSubscriptions(getData.data));

            const getDataAbonents = yield call(xsiEventsGetAbonentsSubscribedTo, payload);
            const codeResponseAbonents = Number.parseInt(getDataAbonents.status, 10);

            if (codeResponseAbonents === 200) {
                yield put(callsActions.setXsiEventsAbonentsList(getDataAbonents.data));
            } else {
                yield put(appActions.setNotification({type: 'error', message: 'Ошибка при получении списка подписок: ' + codeResponseAbonents}));
                yield put(callsActions.setXsiEventsSubscriptions(null));
            }
        }
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(callsActions.setXsiEventsSubscriptions(null));
    }
}

/* Получаем абонентов, на которых подписаны */
function* fetchXsiEventsAbonents({ payload }) {
    try {
        const getData = yield call(xsiEventsGetAbonentsSubscribedTo, payload);
        const codeResponse = Number.parseInt(getData.status, 10);

        if (codeResponse === 200) {
            yield put(callsActions.setXsiEventsAbonentsList(getData.data));
        } else {
            yield put(callsActions.setXsiEventsAbonentsList([]));
        }
    } catch (error) {
        const errorResponse = ErrorResponse(error.response);
        yield put(appActions.setNotification({type: 'error', message: errorResponse}));
        yield put(callsActions.setXsiEventsAbonentsList([]));
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* callsSaga() {
    yield takeEvery(callsActions.fetchCallHistory.type, fetchCallHistory);
    yield takeEvery(callsActions.fetchXsiEventsSubscribe.type, fetchXsiEventsSubscribe);
    yield takeEvery(callsActions.fetchXsiEventsUnsubscribe.type, fetchXsiEventsUnsubscribe);
    yield takeEvery(callsActions.fetchXsiEventsGetSubscriptions.type, fetchXsiEventsGetSubscriptions);
    yield takeEvery(callsActions.fetchXsiEventsAbonents.type, fetchXsiEventsAbonents);
}
