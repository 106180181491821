/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import css from './Topnav.module.scss'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CallsIcon from "../../assets/icons/calls-icon";
import XsiForm from "../calls/call-history/xsiEvents/XsiForm";
import {callsSelectors} from "../../redux/toolkit/calls/selectors";
import CheckAccess from "../../components/auth/CheckAccess";

const TopnavCallsIndicator = () => {
    const {subscriptions, calls, loading} = useSelector(callsSelectors.callsXsiEvents);
    const {currentUser} = useSelector((state) => state.authUser);
    const userHasAccess = !!currentUser && CheckAccess(2, 'zvonk1728632218');
    const [formIsOpened, setFormIsOpend] = useState(false);
    const btn_class = (!!subscriptions.length || loading) ? '':css.error;

    const onClick = () => {
        setFormIsOpend(!formIsOpened);
    }
    const CallsIndicators = () => {
        return (
            <div className={css.callIndicators}>
                <div className={css.counter}>{calls.length}</div>
            </div>
        );
    }

    return (
        <>
            {userHasAccess &&
                <div className={`${css.callsIndicatorContainer} ${btn_class}`}>
                    <button
                        onClick={onClick}
                        type="button"
                        className={`btn btn-sm ${css.btnIndicators}`}
                    >
                        <div className={css.iconsWrapper}>
                            <CallsIcon/>
                            {!subscriptions.length &&
                                <PriorityHighIcon fontSize="medium" className={css.errorNoSubs}/>
                            }
                        </div>
                        <CallsIndicators/>
                    </button>
                    <XsiForm
                        isVisible={formIsOpened}
                        setIsVisible={setFormIsOpend}
                    />
                </div>
            }
        </>
    )
};
export default TopnavCallsIndicator;