import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {callsActions} from "../../../../../redux/toolkit/calls/slice";
import {callsSelectors} from "../../../../../redux/toolkit/calls/selectors";

import css from '../XsiForm.module.scss';
import XsiFormSubscriptionListItem from "./XsiFormSubscriptionListItem";
import XsiFormSubscriptionEmpty from "./XsiFormSubscriptionEmpty";
import XsiFormSubscriptionListHeader from "./XsiFormSubscriptionListHeader";


const XsiFormSubscriptionList = props => {
    const {subscriptions} = useSelector(callsSelectors.callsXsiEvents);
    const dispatch = useDispatch();

    const onUnsubscribe = (id = '') => {
        dispatch(
            callsActions.fetchXsiEventsUnsubscribe({
                subscription_id: id,
            }),
        )
    }
    const onGetSubscribes = () => {
        dispatch(
            callsActions.fetchXsiEventsGetSubscriptions(),
        )
    }

    return (
        <div className={css.xsiSubscriptions}>
            <XsiFormSubscriptionListHeader onGetSubscribes={onGetSubscribes} onUnsubscribe={onUnsubscribe}/>
            {
                subscriptions.length > 0
                    ? (<div className={css.xsiSubscriptionList}>
                        {
                            subscriptions.map((item, index) => {
                                return <XsiFormSubscriptionListItem
                                    key={item.subscription_id}
                                    item={item}
                                    index={index}
                                    onUnsubscribe={onUnsubscribe}
                                />
                            })
                        }
                    </div>)
                    : (<XsiFormSubscriptionEmpty/>)
            }
        </div>
    )
}
export default XsiFormSubscriptionList;