import React from 'react';

import css from '../XsiForm.module.scss';


const XsiFormAbonentListItem = props => {
    const {data} = props;
    const abonentStateClass = {
        'Busy': css.busy,
        'Free': css.online,
        'Unknown': '',
    };

    const AbonentInfo = () => {
        let phone = data.a_id ? (data.phone ? data.phone : data.extension) : (data.pattern ? data.pattern : 'Все звонки');
        const name = data.a_id ? data.first_name : data.target_type;
        return (
            <>
                <span className={css.abonentPhone}>{phone}</span>
                <span className={css.abonentName}>{name}</span>
            </>
        );
    }

    const NoAbonentInfo = () => {
        return <>
            <span>{data.pattern}</span>
            <span>{data.target_type}</span>
        </>
    }

    return (
        <div className={`${css.abonentItem}`}>
                <div className={`${css.state} ${abonentStateClass[data.status] ?? 'Unknown'}`}></div>
                <div className={css.info}>
                    <AbonentInfo/>
                </div>
        </div>
    )
}
export default XsiFormAbonentListItem;