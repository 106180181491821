// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Content_contentEdit__m9qs5.Content_highlightedPlaceholder__bH1mn:empty::before {
  color: pink !important;
  white-space: pre-wrap; }

@media (max-width: 599px) {
  .Content_contentEdit__m9qs5 table {
    width: 250px !important; } }
`, "",{"version":3,"sources":["webpack://./src/containers/modal/ModalCreateTask/Content.module.scss"],"names":[],"mappings":"AAEA;EAGM,sBAAsB;EACtB,qBAAqB,EAAA;;AAKvB;EATJ;IAUM,uBAAuB,EAAA,EAE1B","sourcesContent":["@import '../../../../src/assets/css/sass/_02_variables.scss';\n\n.contentEdit {\n  &.highlightedPlaceholder {\n    &:empty::before {\n      color: pink !important;\n      white-space: pre-wrap;\n    }\n  }\n\n  table {\n    @media ($phonesMax) {\n      width: 250px !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentEdit": `Content_contentEdit__m9qs5`,
	"highlightedPlaceholder": `Content_highlightedPlaceholder__bH1mn`
};
export default ___CSS_LOADER_EXPORT___;
