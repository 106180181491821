import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {colorTask} from "../../../../constants/entityColor";
import {Box, LinearProgress, DialogContent, DialogTitle, IconButton, Tab} from "@mui/material";
import {StyledDialog} from "../../../../utils/mui/dialog";
import CloseIcon from "@mui/icons-material/Close";
import {Loader1} from "../../../../components/svg/Loader";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {callsSelectors} from "../../../../redux/toolkit/calls/selectors";
import {callsActions} from "../../../../redux/toolkit/calls/slice";

import XsiFormPreferences from "./XsiFormPreferences";
import XsiFormSubscriptionList from "./xsiSubscriptionList/XsiFormSubscriptionList";
import css from './XsiForm.module.scss';
import XsiFormCallList from "./formCallIList/XsiFormCallList";
import XsiFormAbonentList from "./formAbonentList/XsiFormAbonentList";

const XsiForm = props => {
    const {isVisible, setIsVisible} = props;
    const {loading, loadingEvent, calls, subscriptions, abonents} = useSelector(callsSelectors.callsXsiEvents);
    const modal = useRef()
    const taskLoading = false;
    const [activeTab, setActiveTab] = useState('calls');
    const dispatch = useDispatch();

    const LoadingStatus = () => {
        return (
            <>
                {!!loadingEvent &&
                <span className={css.loadingStatus}>
                    {' - ' + loadingEvent}...
                    <LinearProgress size={12}/>
                </span>
                }
            </>
        );
    }

    const onClose = () => {
        setIsVisible(false);
    }

    const onChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    }

    const LabelCalls = () => {
        return <div className={css.tabLabel}>
            Звонки <span className={css.counter}>{calls.length}</span>
        </div>
    }

    const LabelSubscription = () => {
        return <div className={css.tabLabel}>
            Подписки <span className={css.counter}>{subscriptions.length}</span>
        </div>
    }

    useEffect(() => {
        if (!subscriptions.length) {
            dispatch(callsActions.fetchXsiEventsGetSubscriptions());
        }
    }, []);

    return (
        <StyledDialog
            open={isVisible}
            maxWidth="100%"
            onClose={onClose}
            className={`modal-dialog-mui`}
            sx={{
                '.MuiPaper-root': {
                    border: `1px solid ${colorTask}`
                }
            }}
            ref={modal}
        >
            <DialogTitle>
                Панелька с входящими звонками и настройками
                <LoadingStatus/>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: 'secondary',
                        position: 'absolute',
                        right: '20px',
                        top: '13px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={`${css.xsiEventsCalls} ${(!!subscriptions.length || loading) ? '' : css.noCalls}`}>
                {taskLoading ? (
                    <div style={{height: '300px'}} className="d-flex align-items-center justify-content-center">
                        <Loader1 />
                    </div>
                ) : (
                    <>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={onChangeTab} aria-label="lab API tabs example">
                                <Tab label={<LabelCalls/>} value="calls" />
                                <Tab label={<LabelSubscription/>} value="subscriptions" />
                            </TabList>
                        </Box>
                        <TabPanel value="calls" sx={{padding:"1.5rem 0 0"}}>
                            <div className="row">
                                <div className="col-2">
                                    <XsiFormPreferences/>
                                </div>
                                <div className="col-10">
                                    <XsiFormAbonentList/>
                                    <XsiFormCallList/>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value="subscriptions" sx={{padding:"1.5rem 0 0"}}>
                            <XsiFormSubscriptionList/>
                        </TabPanel>

                    </TabContext>
                    </>
                )}
            </DialogContent>
        </StyledDialog>
    )
}
export default XsiForm;