import React from 'react';

import css from '../XsiForm.module.scss';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import {adminRoot} from "../../../../../constants/defaultValues";
import {NavLink} from "react-router-dom";


const XsiFormCallListItem = props => {
    const {data} = props;
    const currentCallState = !!data.call_final_state ? data.call_final_state: data.call_state;

    const abonentStateClass = {
        'On-Hook': css.busy,
        'Off-Hook': css.online,
        'Unknown': '',
    };
    const callStatusClass = {
        'Alerting': css.alerting,
        'Active': css.active,
        'Missed': css.missed,
        'NoAnswer': css.noAnswer,
        'Done': css.done,
        'Unknown': '',
    }
    const TopInfo = () => {
        return (
            <div className={css.topInfoBlock}>
                { data.is_group_call &&
                <div className={css.callType}>
                    <GroupsIcon fontSize="small"/>
                </div>
                }
                <div className={css.startTime}>
                    <AccessTimeIcon fontSize="small"/>
                    {data.call_time?.start}
                </div>
            </div>
        );
    }

    const CallClientOrg = () => {
        const org = data.call_client_data?.info?.organization;
        const type_name = org?.types?.findIndex(x => x.id === 2) !== -1 ? 'buyers' : 'dealers';
        return (
            <>
            {!!org &&
                <div className={css.clientOrg}>
                    <NavLink className="nav-link-style-1 text-left" to={`${adminRoot}/partners/${type_name}/${org.id}`} target="_blank" rel="noopener noreferrer">
                        {org.name}
                    </NavLink>
                </div>
                }
            </>
        );
    }
    const CallClientInfo = () => {
        return (
            <div className={css.fromWrapper}>
                <div className={css.clientInfo}>
                    <div className={css.phone}>{data.call_client_data?.phone}</div>
                    <div className={css.userId}>{data.call_client_data?.info?.id}</div>
                    <div className={css.name}>{data.call_client_data?.info?.name ?? 'неизвестный'}</div>
                </div>
                <CallClientOrg/>
                <ResponsibleAbonent/>
            </div>
        );
    }
    const ResponsibleAbonent = () => {
        const resp = data.call_client_data?.info?.organization?.responsible ?? null;
        const name = resp?.name;
        const dep_name = resp?.crmGroups?.length ? resp?.crmGroups[0].name : '';
        const phones = resp?.phones ?? [];
        return (
            <>
                {!!resp &&
                    <div className={css.responsibleAbonent}>
                        <div className={css.respUserId}>С-{resp?.id}</div>
                        <span>Ответственный:</span>
                        <span>{name}</span>
                        <span>{dep_name}</span>
                        <span>
                    {
                        phones.length > 0 && phones[0].number
                    }
                </span>
                    </div>
                }
            </>
        );
    }
    const CallDirection = () => {
        const redirectInfo = !!data.eventData?.call?.redirections ? 'redirect' : '';
        return (
            <div className={css.direction}>
                {redirectInfo && <div>{redirectInfo}</div>}
                {data.direction === 'INBOUND' ?
                    (<KeyboardDoubleArrowDownIcon/>)
                    : (<NorthOutlinedIcon/>)
                }
                <OrderInfo/>
            </div>
        );
    }
    const CallAbonentInfo = () => {
        const phone = data.call_abonent_data?.phone;
        const ext = data.call_abonent_data?.info?.extension;
        const name = data.call_abonent_data?.info?.first_name;
        const date_answer = data.call_time?.answer;
        const date_release = data.call_time?.release;
        const dep_name = data.call_abonent_data?.info?.department?.name;
        const abonent_user_id = data.call_abonent_data?.info?.user_id;

        return (
            <div className={css.abonentWrapper}>
                <div className={css.topInfo}>
                    <div className={css.phone}>{phone}</div>
                    {!!ext && <div className={css.phone}>(доп. {ext})</div>}
                    <div className={css.name}>{name}</div>

                    <div className={css.abonentIds}>
                        <div>А-{data.call_abonent_data?.info?.id}</div>
                        {abonent_user_id &&
                            <div>С-{abonent_user_id}</div>
                        }
                    </div>
                </div>

                <div className={css.callTime}>
                    {!!date_answer &&
                        <div>ответил: <span>{date_answer}</span></div>
                    }
                    {!date_answer && !!date_release &&
                        <div>завершен: <span>{date_release}</span></div>
                    }
                    {date_answer && !!date_release &&
                        <div>завершил: <span>{date_release}</span></div>
                    }
                </div>

                <div className={css.bottomInfo}>
                    <div>{dep_name}</div>
                    <div className={css.stateWrapper}>статус абонента: <div
                        className={`${css.state} ${abonentStateClass[data.abonent_state] ?? 'Unknown'}`}></div>
                    </div>
                </div>
            </div>
        );
    }
    const CallExtInfo = () => {
        const call_id = data?.call_id;
        const main_call_id = data?.main_call_id;

        return (
            <>
            {!!call_id && <div className={css.callId}>{data?.call_id}</div>}
            {!!main_call_id && <div className={css.callId}>{data?.main_call_id}</div>}
            </>
        );
    }
    const RedirectsInfo = () => {
        return (
            <>
                {!!data?.redirect_from && <div>Переадресован с...</div>}
                {!!data?.redirect_to && <div>Переадресован на {data?.redirect_to}</div>}
            </>
        );
    }

    const FinalStatus = () => {
        return (
            <>
                {!!data.call_final_state ?
                    (<div className={`${css.finalCallStatus}`}>
                        {data.call_final_state.name}
                    </div>)
                    : (<div className={css.callStatus}>{data.call_state?.name}</div>)
                }
            </>
        );
    }
    const OrderInfo = () => {
        const resp = (data.responsible_data?.id) ? ('С-' + data.responsible_data?.id) : ('G-' + data.responsible_data?.group_id);
        return (
            <>
                {data.order_id &&
                    <div className={css.orderInfo}>
                        <NavLink className="nav-link-style-1 text-left" to={`${adminRoot}/crm/orders/${data.order_id}`} target="_blank" rel="noopener noreferrer">
                            Заказ {data.order_id}
                        </NavLink>
                        <div className={css.resp}>
                            ({resp})
                        </div>
                    </div>
                }
            </>
        );
    }

    return (
        <div className={`${css.callListItem} ${callStatusClass[currentCallState?.code] ?? ''}`}>
            <FinalStatus/>
            <TopInfo/>
            <CallClientInfo/>
            <CallDirection/>
            <RedirectsInfo/>
            <div>
                <CallAbonentInfo/>
                <CallExtInfo/>
            </div>
        </div>
    )
}
export default XsiFormCallListItem;