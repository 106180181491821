import React from 'react';

import {useDispatch, useSelector} from "react-redux";
import {callsActions} from "../../../../../redux/toolkit/calls/slice";
import {callsSelectors} from "../../../../../redux/toolkit/calls/selectors";

import css from '../XsiForm.module.scss';

const XsiFormCallListClearBtn = props => {
    const {calls} = useSelector(callsSelectors.callsXsiEvents);
    const dispatch = useDispatch();
    const onClear = () => {
        dispatch(
            callsActions.resetXsiEventsCalls(),
        )
    }
    const onDoneClear = () => {
        dispatch(
            callsActions.resetXsiEventsCalls('Done'),
        )
    }
    const onNoAnswerClear = () => {
        dispatch(
            callsActions.resetXsiEventsCalls('NoAnswer'),
        )
    }
    const onMissedClear = () => {
        dispatch(
            callsActions.resetXsiEventsCalls('Missed'),
        )
    }

    return (
        <>
            {calls?.length > 0 &&
                <div className={css.callListBtns}>
                    <div className={css.blockLeft}>
                    <button
                        onClick={onDoneClear}
                        type="button"
                        className={`btn ${css.resetBtn}`}
                    >
                        <span className="icon">
                            <i className="simple-icon-minus"/>
                        </span>
                        <span>Завершен</span>
                    </button>
                    <button
                        onClick={onNoAnswerClear}
                        type="button"
                        className={`btn ${css.resetBtn}`}
                    >
                        <span className="icon">
                            <i className="simple-icon-minus"/>
                        </span>
                        <span>Нет ответа</span>
                    </button>

                    <button
                        onClick={onMissedClear}
                        type="button"
                        className={`btn ${css.resetBtn}`}
                    >
                        <span className="icon">
                            <i className="simple-icon-minus"/>
                        </span>
                        <span>Пропущен</span>
                    </button>
                    </div>

                    <button
                        onClick={onClear}
                        type="button"
                        className={`btn ${css.resetBtn}`}
                    >
                        <span className="icon">
                            <i className="simple-icon-minus"/>
                        </span>
                        <span>Очистить список звонков</span>
                    </button>
                </div>
            }
        </>
    )
}
export default XsiFormCallListClearBtn;