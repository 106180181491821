import axios from 'axios';
import {getToken} from '../helpers/Utils';

const defaultOptions = (version = 1) => {
    // const currentHostName = window.location.hostname;
    return {
        baseURL: `https://api.stoking.ru/v${version}/`,
        // baseURL: 'https://api.comtest.stoking.ru/v1/',
        //baseURL: `http://api.stoking/v${version}/`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ',
        },
    };
};

export const PostData = (url, body = {}, query = {}) => {
    const options = defaultOptions();
    options.headers.Authorization = `Bearer ${getToken()}`;
    if (body instanceof FormData) {
        options.headers['Content-Type'] = 'multipart/form-data';
    }
    return axios.create(options).post(url, body, { params: query });
};
export const PutData = (url, body = {}, query = {}) => {
    const options = defaultOptions();
    options.headers.Authorization = `Bearer ${getToken()}`;
    if (body instanceof FormData) {
        options.headers['Content-Type'] = 'multipart/form-data';
    }
    return axios.create(options).put(url, body, { params: query });
};
export const GetData = (url, values = {}, version = 1) => {
    const options = defaultOptions(version);
    options.headers.Authorization = `Bearer ${getToken()}`;

    return axios.create(options).get(url, { params: values });
};
export const DeleteData = (url, values = {}) => {
    const options = defaultOptions();
    options.headers.Authorization = `Bearer ${getToken()}`;

    return axios.create(options).delete(url, { params: values });
};
