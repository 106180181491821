import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {callsActions} from "../../../../redux/toolkit/calls/slice";
import {callsSelectors} from "../../../../redux/toolkit/calls/selectors";

import css from './XsiForm.module.scss';
import CheckAccess from "../../../../components/auth/CheckAccess";


const xsiParamsDefault = {
    pattern:'',
    time_sec: 3600,
    call_type: 'BASIC_CALL',
    all: false,
};


const XsiFormPreferences = props => {
    const {loading} = useSelector(callsSelectors.callsXsiEvents);
    const [xsiParams, setXsiParams] = useState(xsiParamsDefault);
    const isAdmin = CheckAccess(1, 'admin');
    const currentUser = useSelector((state) => state.authUser.currentUser);
    const userIsIT = currentUser?.crmGroups?.some(xg => xg.id === 10);
    const dispatch = useDispatch();


    const onChangeXsiParams = (field, value) => {
        setXsiParams({...xsiParams, [field]: value})
    }

    const onSubscribe = () => {
        dispatch(
            callsActions.fetchXsiEventsSubscribe(xsiParams),
        )
    }
    const onSubscribeAll = () => {
        dispatch(
            callsActions.fetchXsiEventsSubscribe({...xsiParams, all: true}),
        )
    }

    return <>
        {isAdmin && userIsIT &&
        <div className={css.xsiPreferences}>
            <div className={css.xsiParams}>
                <input
                    type="text"
                    className="no-arrows form-control style-2"
                    placeholder="Номер телефона"
                    value={xsiParams.pattern}
                    onChange={(event) => onChangeXsiParams('pattern', event.target.value)}
                />
                <input
                    type="number"
                    className="no-arrows form-control style-2"
                    placeholder="Время жизни подписки, сек"
                    value={xsiParams.time_sec}
                    onChange={(event) => onChangeXsiParams('time_sec', event.target.value)}
                />
            </div>
            <div className={css.xsiButtons}>
                <button
                    onClick={onSubscribe}
                    type="button"
                    className={`btn ${css.subscribe}`}
                    disabled={loading}
                >
                    Подписаться
                </button>
            </div>
            <div className={css.xsiButtons}>
                <button
                    onClick={onSubscribeAll}
                    type="button"
                    className={`btn ${css.subscribe}`}
                    disabled={loading}
                >
                    Подписаться на каждого абонента
                </button>
            </div>
        </div>
        }
    </>
}
export default XsiFormPreferences;