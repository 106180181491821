import React, {useContext} from 'react';
import {Grid, Tooltip} from "@mui/material";
import PhotoManagerNew from "../../../../../components/profile/photoManagerNew";
import {DesktopContext} from "../../../../../utils/hoc/withContext";
import css from './ModalChangeClient.module.scss'

const ModalChangeClientItem = ({
                                   id,
                                   name,
                                   requisites,
                                   responsibleList,
                                   onClickClient,
                                   selectedClient,
                                   typeId,
                               }) => {

    const searchResponsible = responsibleList.find(x => typeId.includes(x.type_id)) ?? null;
    const responsibleData = searchResponsible ? searchResponsible.responsible : null;

    const desktop = useContext(DesktopContext)

    return (
        <>
            {desktop ?
                <tr
                    data-id={id}
                    onClick={onClickClient}
                    className={selectedClient === id ? 'selected' : ''}
                >
                    <td>{id}</td>
                    <Tooltip title={name} placement='top'>
                    <td className="overflow-ellipsis" id={`tooltip_search_client_${id}`}>
                        {name}
                    </td>
                    </Tooltip>
                    <td className="overflow-ellipsis">{requisites?.[0]?.inn}</td>
                    <td className="overflow-ellipsis">
                        {responsibleData ? (
                            <div className="d-flex align-items-center justify-content-start">
                                <PhotoManagerNew
                                    data={responsibleData}
                                />
                                <span className="ml-2">
                                  {responsibleData
                                      ? `${responsibleData.last_name} ${responsibleData.first_name}`
                                      : ''}
                                </span>
                            </div>
                        ) : 'Не назначен'}
                    </td>
                </tr>
                :
                <Grid container data-id={id} className={`${css.clients} ${selectedClient === id ? css.clientsSelect : ''}`} onClick={onClickClient}>
                    <Grid item xs={2} className={css.clientsElem}><p className={css.clientsElemText}>{id}</p></Grid>
                    <Grid item xs={3} className={css.clientsElem}><p className={css.clientsElemText}>{name}</p></Grid>
                    <Grid item xs={2} className={css.clientsElem}><p className={css.clientsElemText}>{requisites?.[0]?.inn}</p></Grid>
                    <Grid item xs={5} className={css.clientsElem}>
                        {
                            responsibleData ? (
                                <>
                                    <PhotoManagerNew
                                        data={responsibleData}
                                    />
                                    <span className="ml-2">
                                        {responsibleData ?
                                            `${responsibleData.last_name} ${responsibleData.first_name}`
                                            : ''}
                                    </span>
                                </>
                            ) : 'Не назначен'
                        }
                    </Grid>
                </Grid>
            }
        </>
    );
};
export default ModalChangeClientItem;
