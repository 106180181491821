/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, {useContext, useEffect, useState} from 'react';
import { injectIntl } from 'react-intl';
import {
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {connect, useDispatch, useSelector} from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {IconButton} from "@mui/material";
import {
    setContainerClassnames,
    clickOnMobileMenu,
    logoutUser,
    changeLocale, fetchModeWatch, menuSetOpenLevel,
} from '../../redux/actions';
import {
    searchPath,
    adminRoot,
    siteUrl
} from '../../constants/defaultValues';
import { MenuIcon } from '../../components/svg';
import TopnavNotifications from './TopnavNotifications';
import PageNav from './PageNav';
import TopnavModeDuty from "./TopnavModeDuty";
import TopnavRates from "./TopnavRates";
import TopnavNotificationsComments from "./TopnavNotificationsComments";
import PhotoManagerNew from "../../components/profile/photoManagerNew";
import {DesktopContext} from "../../utils/hoc/withContext";
import css from './Topnav.module.scss'
import Navigation from "./Navigation/Navigation";
import HouseIcon from "../../assets/icons/house-icon";
import CrmIcon from "../../assets/icons/crm-icon";
import CounterpartiesIcon from "../../assets/icons/counterparties-icon";
import DocumentsIcon from "../../assets/icons/documents-icon";
import StatisticsIcon from "../../assets/icons/statistics-icon";
import LogoutIcon from "../../assets/icons/logout-icon";
import UserIcon from "../../assets/icons/user-icon";
import TopnavCallsIndicator from "./TopnavCallsIndicator";

const TopNav = ({
    history,
    clickOnMobileMenuAction,
    logoutUserAction,
    modeWatch
}) => {
    const dispatch = useDispatch();
    const [searchKeyword, setSearchKeyword] = useState('');
    const { changeModeWatch } = useSelector((state) => state.websocket.event);
    const { currentUser } = useSelector((state) => state.authUser);
    const { openLevel } = useSelector((state) => state.menu);


    const search = () => {
        history.push(`${searchPath}?key=${searchKeyword}`);
        setSearchKeyword('');
    };

    const handleDocumentClickSearch = (e) => {
        let isSearchClick = false;
        if (
            e.target &&
            e.target.classList &&
            (e.target.classList.contains('navbar') ||
                e.target.classList.contains('simple-icon-magnifier'))
        ) {
            isSearchClick = true;
            if (e.target.classList.contains('simple-icon-magnifier')) {
                search();
            }
        } else if (
            e.target.parentElement &&
            e.target.parentElement.classList &&
            e.target.parentElement.classList.contains('search')
        ) {
            isSearchClick = true;
        }

        if (!isSearchClick) {
            const input = document.querySelector('.mobile-view');
            if (input && input.classList) input.classList.remove('mobile-view');
            removeEventsSearch();
            setSearchKeyword('');
        }
    };

    const removeEventsSearch = () => {
        document.removeEventListener('click', handleDocumentClickSearch, true);
    };

    const handleLogout = () => {
        logoutUserAction(history);
    };

    // eslint-disable-next-line no-unused-vars
    const mobileMenuButtonClick = (e, _containerClassnames) => {
        e.preventDefault();
        clickOnMobileMenuAction(_containerClassnames);
    };
    // eslint-disable-next-line no-unused-vars
    const menuButtonClick = (e) => {
        e.preventDefault();

        const newLevel = openLevel ? 0 : 1;
        dispatch(menuSetOpenLevel(newLevel));
    };

    useEffect(() => {
        if (currentUser?.id ?? null) {
            dispatch(fetchModeWatch());
        }
    }, [currentUser]);

    useEffect(() => {
        if (changeModeWatch) {
            dispatch(fetchModeWatch());
        }
    }, [changeModeWatch]);

    const desktop = useContext(DesktopContext)

    return (
        <>
            <nav id="navbar" className={css.navbar}>
                {desktop && <PageNav/>}
                {desktop &&
                    <div className={css.navbarLeft}>
                        <IconButton
                            className={css.menuButton}
                            onClick={(e) =>
                                menuButtonClick(e)
                            }
                        >
                            <MenuIcon/>
                        </IconButton>
                    </div>
                }

                <div className={css.ratesContainer}>
                    <TopnavRates/>
                </div>

                <NavLink className={css.logo} to={{pathname: siteUrl}} target="_blank" title="Перейти в Stoking.ru">
                    <span className={css.logoText}>Stoking</span>
                </NavLink>

                <TopnavCallsIndicator/>

                <div className={css.navbarRight}>
                    {modeWatch && <TopnavModeDuty />}
                    <div className={css.icons}>
                        <TopnavNotificationsComments/>
                        <TopnavNotifications/>
                    </div>
                    <div className={css.user}>
                        <UncontrolledDropdown className="dropdown-menu-right">
                            <DropdownToggle
                                className="p-0 d-flex align-items-center"
                                color="empty"
                            >
                                <span className={css.userName}>
                                    {currentUser ? (
                                        `${currentUser.first_name} ${currentUser.last_name}`
                                    ) : (
                                        <Skeleton width={50}/>
                                    )}
                                </span>
                                <PhotoManagerNew data={currentUser}/>

                            </DropdownToggle>
                            <DropdownMenu
                                className={`${css.topnavMenu} mt-3 topnav-dd-menu`}
                                right
                            >
                                <DropdownItem tag={NavLink} to={`${adminRoot}`} className="topnav-dd-menu__item">
                                    <HouseIcon width='14' height='14'/>
                                    <span style={{color: '#3a3a3a'}}>Главная</span>
                                </DropdownItem>
                                <DropdownItem tag={NavLink} to={`${adminRoot}/profile`}
                                              className="topnav-dd-menu__item">
                                    <UserIcon/>
                                    <span style={{color: '#3a3a3a'}}>Профиль</span>
                                </DropdownItem>
                                <DropdownItem tag={NavLink} to={`${adminRoot}/crm`} className="topnav-dd-menu__item">
                                    <CrmIcon width='14' height='14'/>
                                    <span style={{color: '#3a3a3a'}}>CRM</span>
                                </DropdownItem>
                                <DropdownItem tag={NavLink} to={`${adminRoot}/partners/buyers`}
                                              className="topnav-dd-menu__item">
                                    <CounterpartiesIcon width='14' height='14'/>
                                    <span style={{color: '#3a3a3a'}}>Контрагенты</span>
                                </DropdownItem>
                                <DropdownItem tag={NavLink} to={`${adminRoot}/crm/documents`}
                                              className="topnav-dd-menu__item">
                                    <DocumentsIcon width='14' height='14'/>
                                    <span style={{color: '#3a3a3a'}}>Документы</span>
                                </DropdownItem>
                                <DropdownItem tag={NavLink} to={`${adminRoot}/statistics`}
                                              className="topnav-dd-menu__item">
                                    <StatisticsIcon width='14' height='14'/>
                                    <span style={{color: '#3a3a3a'}}>Статистика</span>
                                </DropdownItem>
                                <DropdownItem tag={NavLink} to={`${adminRoot}/calls`} className="topnav-dd-menu__item">
                                    <StatisticsIcon width='14' height='14'/>
                                    <span style={{color: '#3a3a3a'}}>Звонки</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleLogout()} className="topnav-dd-menu__item">
                                    <LogoutIcon width='15' height='14'/>
                                    <span style={{color: '#3a3a3a'}}>Выход</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </nav>
            {!desktop && <Navigation/>}
        </>
    );
};

const mapStateToProps = ({menu, settings}) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;
    const { locale, modeWatch } = settings;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
        locale,
        modeWatch,
    };
};
export default injectIntl(
    connect(mapStateToProps, {
        setContainerClassnamesAction: setContainerClassnames,
        clickOnMobileMenuAction: clickOnMobileMenu,
        logoutUserAction: logoutUser,
        changeLocaleAction: changeLocale,
    })(TopNav)
);
