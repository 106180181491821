import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import {articlesSaga} from './articles/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import managerSagas from './managers/saga';
import crmOrderSaga from './crm/order/saga';
import crmClientSaga from './crm/client/saga';
import crmTaskSaga from './crm/task/saga';
import crmDocumentSaga from './crm/document/saga';
import notificationSaga from './notification/saga';
import commentSaga from './comment/saga';
import userSaga from './user/saga';
import settingsSaga from './settings/saga';
import crmDealSaga from './crm/deal/saga';
import financeSaga from './finance/saga';
import statisticDealSaga from './statistics/deal/saga';
import orderFeedbackSaga from './crm/orderFeedback/saga';
import {logisticsSaga} from "./toolkit/logistics/saga";
import {storageSaga} from "./toolkit/storage/saga";
import {statisticsSaga} from "./toolkit/statistics/saga";
import {dealerSaga} from "./toolkit/dealer/saga";
import {buyerSaga} from "./toolkit/buyer/saga";
import {taskSaga} from "./toolkit/task/saga";
import {targetSaga} from "./toolkit/target/saga";
import {processSaga} from "./toolkit/process/saga";
import {usersSaga} from "./toolkit/users/saga";
import {scenarioSaga} from "./toolkit/settings/scenario/saga";
import {audienceSaga} from "./toolkit/audience/saga";
import {callsSaga} from "./toolkit/calls/saga";

export default function* rootSaga() {
    yield all([
        authSagas(),
        todoSagas(),
        articlesSaga(),
        chatSagas(),
        surveyListSagas(),
        surveyDetailSagas(),
        managerSagas(),
        crmOrderSaga(),
        crmClientSaga(),
        crmTaskSaga(),
        notificationSaga(),
        commentSaga(),
        crmDocumentSaga(),
        userSaga(),
        settingsSaga(),
        crmDealSaga(),
        financeSaga(),
        statisticDealSaga(),
        orderFeedbackSaga(),
        logisticsSaga(),
        storageSaga(),
        statisticsSaga(),
        dealerSaga(),
        buyerSaga(),
        taskSaga(),
        targetSaga(),
        processSaga(),
        usersSaga(),
        scenarioSaga(),
        audienceSaga(),
        callsSaga(),
    ]);
}
