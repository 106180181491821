import React from 'react'
import {adminRoot, defaultPermissions} from './defaultValues';
import PageNavOrders from "../containers/navs/pageNav/PageNavOrders";
import PageNavTasks from "../containers/navs/pageNav/PageNavTasks";
import HouseIcon from "../assets/icons/house-icon";
import CrmIcon from "../assets/icons/crm-icon";
import CounterpartiesIcon from "../assets/icons/counterparties-icon";
import WarehouseIcon from "../assets/icons/warehouse-icon";
import DocumentsIcon from "../assets/icons/documents-icon";
import StatisticsIcon from "../assets/icons/statistics-icon";
import NotepadIcon from "../assets/icons/notepad-icon";
import PencilIcon from "../assets/icons/pencil-icon";
import CaseIcon from "../assets/icons/case-icon";
import LogisticsIcon from "../assets/icons/logistics-icon";
import BoxIcon from "../assets/icons/box-icon";
import MoneyBagIcon from "../assets/icons/money-bag-icon";
import PeopleIcon from "../assets/icons/people-icon";
import PhoneIcon from "../assets/icons/phone-icon";
import CartIcon from "../assets/icons/cart-icon";
import PageNavTargets from "../containers/navs/pageNav/PageNavTargets";
import PageNavProcess from "../containers/navs/pageNav/PageNavProcess";
import UpdateIcon from "../assets/icons/update-icon";
import SettingsIcon from "../assets/icons/settings-icon";
import {viewWarehouses} from "./permissions";
import CallsIcon from "../assets/icons/calls-icon";

const data = [
    {
        id: 'index',
        // eslint-disable-next-line react/react-in-jsx-scope
        icon: (props) => <HouseIcon {...props}/>,
        label: 'menu.index',
        to: `${adminRoot}`,
        value: 0
    },
    {
        id: 'crm',
        // eslint-disable-next-line react/react-in-jsx-scope
        icon: (props) => <CrmIcon {...props}/>,
        label: 'menu.crm',
        to: `${adminRoot}/crm`,
        children: [
            {
                id: 'crm/orders',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <NotepadIcon {...props}/>,
                label: 'menu.crm.orders',
                to: `${adminRoot}/crm/orders`,
                name: 'Заказы',
                component: PageNavOrders
            },
            {
                id: 'crm/tasks',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <PencilIcon {...props}/>,
                label: 'menu.crm.tasks',
                to: `${adminRoot}/crm/tasks`,
                name: 'Задачи',
                component: PageNavTasks
            },
            {
                id: 'crm/deals',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <CaseIcon {...props}/>,
                label: 'menu.deals',
                to: `${adminRoot}/crm/deals`,
                name: 'Сделки',
            },
            {
                id: 'crm/targets',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <CaseIcon {...props}/>,
                label: 'menu.crm.targets',
                to: `${adminRoot}/crm/targets`,
                name: 'Цели',
                component: PageNavTargets,
                permissions: [defaultPermissions.target_list]
            },
            {
                id: 'crm/process',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <CaseIcon {...props}/>,
                label: 'menu.crm.process',
                to: `${adminRoot}/crm/process`,
                name: 'Процессы',
                component: PageNavProcess,
                permissions: [defaultPermissions.process_view]
            },
            {
                id: 'crm/logistics',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <LogisticsIcon {...props}/>,
                label: 'menu.comments',
                to: `${adminRoot}/crm/logistics`,
                name: 'Логистика',
                permissions: ['prosm1669278703']
            },
            {
                id: 'crm/feedback',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <DocumentsIcon {...props}/>,
                label: 'menu.comments',
                to: `${adminRoot}/crm/feedback`,
                name: 'Отзывы',
            },
        ],
        permissions: ['prosm1628105993', 'prosm1632215354', 'prosm1632466840', 'prosm1669278703'],
        // roles: [UserRole.Admin, UserRole.Editor],
    },
    {
        id: 'clients',
        // eslint-disable-next-line react/react-in-jsx-scope
        icon: (props) => <CounterpartiesIcon {...props}/>,
        label: 'menu.crm-client',
        to: `${adminRoot}/partners`,
        children: [
            {
                id: 'clients/clients',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <MoneyBagIcon {...props}/>,
                label: 'menu.crm.orders',
                to: `${adminRoot}/partners/buyers`,
                name: 'Покупатели',
            },
            {
                id: 'clients/dealers',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <BoxIcon {...props}/>,
                label: 'menu.crm-dealers',
                to: `${adminRoot}/partners/dealers`,
                name: 'Поставщики',
                permissions: ['prosm1670835105']
            },
            {
                id: 'clients/audience',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <BoxIcon {...props}/>,
                label: 'menu.crm-audience',
                to: `${adminRoot}/partners/audiences`,
                name: 'Аудитории',
                permissions: ['prosm1720527297']
            },
            // {
            //     id: 'clients/dealers-tmp',
            //     icon: 'simple-icon-paper-plane',
            //     label: 'menu.crm-dealers',
            //     to: `${adminRoot}/partners/dealers-tmp`,
            //     name: 'Временные поставщики',
            //     permissions: ['prosm1670835105']
            // },
        ],
        permissions: ['prosm1632466571', 'prosm1670835105']
    },
    {
        id: 'storages',
        // eslint-disable-next-line react/react-in-jsx-scope
        icon: (props) => <WarehouseIcon {...props}/>,
        label: 'menu.crm-storages',
        to: `${adminRoot}/crm/storages`,
        permissions: ['prosm1619433246', viewWarehouses],
    },
    {
        id: 'documents',
        // eslint-disable-next-line react/react-in-jsx-scope
        icon: (props) => <DocumentsIcon {...props}/>,
        label: 'menu.crm-document',
        to: `${adminRoot}/crm/documents`,
        // roles: [UserRole.Admin, UserRole.Editor],
        permissions: ['prosm1632466656'],
    },
    {
        id: 'statistics',
        // eslint-disable-next-line react/react-in-jsx-scope
        icon: (props) => <StatisticsIcon {...props}/>,
        label: 'menu.statistics',
        to: `${adminRoot}/statistics`,
        // roles: [UserRole.Admin, UserRole.Editor],
        permissions: ['stati1674645061'],
        children: [
            {
                id: 'statistics/managers',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <PeopleIcon {...props}/>,
                label: 'menu.crm.orders',
                to: `${adminRoot}/statistics/managers`,
                name: 'По менеджерам',
            },
            {
                id: 'statistics/calls',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <PhoneIcon {...props}/>,
                label: 'menu.crm.orders',
                to: `${adminRoot}/statistics/calls`,
                name: 'По звонкам',
            },
            {
                id: 'statistics/dealers',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <BoxIcon {...props}/>,
                label: 'menu.crm.orders',
                to: `${adminRoot}/statistics/dealers`,
                name: 'По поставщикам',
                // permissions: ['pop
                // o1662968053']
            },
            {
                id: 'statistics/products',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <CartIcon {...props}/>,
                label: 'menu.crm.orders',
                to: `${adminRoot}/statistics/products`,
                name: 'По товарам',
            },
            {
                id: 'statistics/published-cards',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <CartIcon {...props}/>,
                label: 'menu.crm.orders',
                to: `${adminRoot}/statistics/published-cards`,
                name: 'По опубликованным карточкам',
            },
            {
                id: 'statistics/moderators',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <CartIcon {...props}/>,
                label: 'menu.crm.orders',
                to: `${adminRoot}/statistics/moderators`,
                name: 'По модераторам',
            },
            {
                id: 'statistics/suppliers',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <CartIcon {...props}/>,
                label: 'menu.statistics.suppliers',
                to: `${adminRoot}/statistics/suppliers`,
                name: 'По снабженцам',
            },
        ]
    },
    {
        id: 'calls',
        // eslint-disable-next-line react/react-in-jsx-scope
        icon: (props) => <CallsIcon {...props}/>,
        label: 'menu.calls',
        to: `${adminRoot}/calls`,
        permissions: ['zvonk1728632218'],
        children: [
            {
                id: 'calls/call-history',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <PeopleIcon {...props}/>,
                to: `${adminRoot}/calls/call-history`,
                name: 'История звонков',
            },
            // {
            //     id: 'calls/call-recordings',
            //     // eslint-disable-next-line react/react-in-jsx-scope
            //     icon: (props) => <PhoneIcon {...props}/>,
            //     to: `${adminRoot}/calls/call-recordings`,
            //     name: 'Записи разговоров',
            // },
        ]
    },
    {
        id: 'updates',
        // eslint-disable-next-line react/react-in-jsx-scope
        icon: (props) => <UpdateIcon {...props}/>,
        label: 'menu.updates',
        to: `${adminRoot}/updates`,
        //value: 0
    },
    {
        id: 'settings',
        // eslint-disable-next-line react/react-in-jsx-scope
        icon: (props) => <SettingsIcon {...props}/>,
        label: 'menu.settings',
        to: `${adminRoot}/settings`,
        permissions: ['crms1629778467'],
        children: [
            {
                id: 'settings/scenarios',
                // eslint-disable-next-line react/react-in-jsx-scope
                icon: (props) => <NotepadIcon {...props}/>,
                label: 'menu.settings.scenarios',
                to: `${adminRoot}/settings/scenarios`,
                name: 'Сценарии',
            },
        ]
    }
];
export default data;
