import React from 'react';

import css from '../XsiForm.module.scss';
import {useSelector} from "react-redux";
import {callsSelectors} from "../../../../../redux/toolkit/calls/selectors";


const XsiFormSubscriptionListItem = props => {
    const {item, index, onUnsubscribe} = props;
    const {loading} = useSelector(callsSelectors.callsXsiEvents);

    return <div key={item.subscription_id} className={css.item}>
        <div className={css.subscriptionIndex}>{index + 1}</div>
        <div className={css.title}>
            <button
                onClick={() => onUnsubscribe(item.subscription_id)}
                type="button"
                className="btn btn-secondary"
                disabled={loading}
            >
                Удалить подписку
            </button>

        </div>
        <ul>
            <li>{item.subscription_id}</li>
            <li>с {item.datetime_start}</li>
            <li>по {item.datetime_end}</li>
            <li>осталось(сек): {item.expires}</li>
            <li>pattern:
                {item.pattern
                    ? <span className={css.phone}>{item.pattern}</span>
                    : <span className={css.phone}> все звонки</span>
                }
            </li>

            {!!item.abonent &&
                <>
                    {item.abonent?.phone && <li>номер: <span className={css.phone}>{item.abonent?.phone}</span></li>}
                    <li>доб.: <span className={css.phone}>{item.abonent?.extension}</span></li>
                    <li className={css.abonentInfo}>{item.abonent?.first_name} ({item.abonent?.extension}) {item.abonent?.department?.name}</li>
                </>
            }
            <li>target_type: {item.target_type}</li>
            <li>target_id: {item.target_id}</li>
            <li>{item.subscriptionType?.name}</li>
        </ul>
    </div>
}
export default XsiFormSubscriptionListItem;