import {Stack} from '@mui/material'
import {Loader1} from '../../../svg/Loader'

const LoaderOverlay = () => (
    <Stack
        position="absolute"
        alignItems="center"
        justifyContent="center"
        left={0}
        right={0}
        top={0}
        bottom={0}
        bgcolor="#ccc6">
        <Loader1 />
    </Stack>
)

export default LoaderOverlay
