/* eslint-disable no-unused-vars,no-param-reassign */
import {createSlice} from "@reduxjs/toolkit";
import {fetchMetaDefault} from "../../../constants/defaultCrm";

const initialState = {
    callHistory: {
        loading: false,
        data: {
            items: [],
            meta: fetchMetaDefault
        },
        reload: false,
    },
    xsiEvents: {
        subscriptions: [],
        calls: [],
        abonents: [],
        loading: false,
        loadingEvent: '',
        errors: [],
    },
};

const slice = createSlice({
    name: "calls",
    initialState,
    reducers: {
        setCallHistory: (state, { payload }) => {
            state.callHistory = {
                ...state.callHistory,
                data: {
                    items: !payload ? [] : payload.items,
                    meta: !payload ? fetchMetaDefault : payload._meta
                },
                loading: false,
                reload: false,
            };
        },
        fetchCallHistory: (state, { payload }) => {
            state.callHistory = {
                ...state.callHistory,
                loading: true,
                reload: false,
            };
        },
        reloadCallHistory: (state, { payload }) => {
            state.callHistory.reload = payload;
        },

        setXsiEventsErrors: (state, { payload }) => {
            state.xsiEvents = {
                ...state.xsiEvents,
                loading: false,
                loadingEvent: '',
                errors: payload,
            };
        },

        fetchXsiEventsSubscribe: (state, { payload }) => {
            state.xsiEvents = {
                ...state.xsiEvents,
                loading: true,
                loadingEvent: 'подписываемся на абонентов',
                errors: [],
            };
        },

        fetchXsiEventsUnsubscribe: (state, { payload }) => {
            state.xsiEvents = {
                ...state.xsiEvents,
                loading: true,
                loadingEvent: 'удаляем подписки',
                errors: [],
            };
        },

        fetchXsiEventsGetSubscriptions: (state, { payload }) => {
            state.xsiEvents = {
                ...state.xsiEvents,
                subscriptions: [],
                loading: true,
                loadingEvent: 'получаем подписки',
                errors: [],
            };
        },
        setXsiEventsSubscriptions: (state, { payload }) => {
            state.xsiEvents = {
                ...state.xsiEvents,
                subscriptions: payload?.items ?? [],
                loading: false,
                loadingEvent: '',
                errors: [],
            };
        },

        resetXsiEventsCalls: (state, { payload }) => {
            const type = payload ?? '';
            state.xsiEvents = {
                ...state.xsiEvents,
                calls: !type ? [] : state.xsiEvents.calls.filter(x => x.call_final_state?.code !== type),
            };
        },

        //Входящие\исходящие звонки
        setXsiEventsNewCall: (state, { payload }) => {
            const call_info = payload?.data ?? [];
            const call_index = state.xsiEvents.calls?.length ? state.xsiEvents.calls.findIndex(x => x.call_id === call_info.call_id) : -1;
            let all_calls = [];

            if (call_index === -1) {//Если это новый звонок - добавим (может прийти в любом статусе!)
                const call_item = {
                    order_id: call_info.order_id ?? null,
                    responsible_data: call_info.responsible_data,
                    call_id: call_info.call_id,
                    main_call_id: call_info.main_call_id,
                    redirect_from: call_info.redirect_from,
                    redirect_to: call_info.redirect_to,
                    is_group_call: call_info.is_group_call,
                    call_state: call_info.call_state,
                    call_final_state: call_info.call_final_state,
                    direction: call_info.direction,
                    call_client_data: call_info.client_data,
                    call_abonent_data: call_info.abonent_data,
                    call_time: call_info.call_time,
                    abonent_state: '',
                    targetId: call_info.targetId,
                    userId: call_info.userId,
                    items: [call_info]
                }
                all_calls = !state.xsiEvents.calls?.length ? [] : state.xsiEvents.calls;
                all_calls.push(call_item);
            } else {//обновим звонок
                const call =  state.xsiEvents.calls[call_index];
                const call_modified = {...call,
                    call_state: call_info.call_state,
                    call_final_state: call_info.call_final_state,
                    call_time: {...call.call_time, ...call_info.call_time},
                    redirect_to: call_info.redirect_to,
                    items: [...call.items, call_info],
                    order_id: call_info.order_id ?? call.order_id,
                }
                all_calls =  [...state.xsiEvents.calls];
                all_calls[call_index] = call_modified;
            }

            //При групповом звонке (если ответили): обновим статус родительского звонка
            if ( call_info.is_group_call
                && call_info.call_final_state?.code === 'Done' && !call_info.abonent_data?.is_group )
            {
                const call_parent_index = state.xsiEvents.calls?.length
                    ? all_calls.findIndex(x => x.main_call_id === call_info.main_call_id && x.call_abonent_data?.is_group && x.call_final_state?.code !== 'Done')
                    : -1;

                if (call_parent_index !== -1) {
                    const parent_call = all_calls[call_parent_index];
                    const parent_call_modified = {...parent_call,
                        call_final_state: call_info.call_final_state,
                        call_time: {...parent_call.call_time, ...call_info.call_time},
                    }
                    all_calls[call_parent_index] = parent_call_modified;
                }
            }

            state.xsiEvents = {
                ...state.xsiEvents,
                calls: all_calls
            };
        },

        /* Абоненты, на которых подписаны */
        fetchXsiEventsAbonents: (state, { payload }) => {
            state.xsiEvents = {
                ...state.xsiEvents,
                loading: true,
                loadingEvent: 'получаем абонентов',
                errors: [],
            };
        },

        setXsiEventsAbonentsList: (state, { payload }) => {
            state.xsiEvents = {
                ...state.xsiEvents,
                abonents: payload,
                loading: false,
                loadingEvent: '',
                errors: [],
            };
        },

        /* Смена статуса у абонента */
        setXsiEventsAbonentStatus: (state, { payload }) => {
            const data = payload?.data ?? [];
            const abonent_index = state.xsiEvents.abonents?.length ? state.xsiEvents.abonents.findIndex(x => x.id === data.abonent_info?.id) : -1;
            let abonents = [...state.xsiEvents.abonents];

            if (abonent_index !== -1) {
                abonents[abonent_index].status = data.status;
                state.xsiEvents = {
                    ...state.xsiEvents,
                    abonents: abonents
                };
            }
        }
    }
})

export const callsReducer = slice.reducer;
export const callsActions = slice.actions;