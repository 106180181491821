import React, {Suspense, useEffect, useRef, useState} from 'react';
import {Redirect, Route, Switch, useLocation, withRouter} from 'react-router-dom';
import {connect, useDispatch, useSelector} from 'react-redux';
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import {send} from "@giantmachines/redux-websocket/dist";
import AppLayout from '../../layout/AppLayout';
import {ProtectedRoute} from '../../helpers/authHelper';
import {setCountNewOrder, setCountYouResponsibleOrder} from "../../redux/crm/order/actions";
import {setCountNewTasks, setCountOpenedTasks, setCountTasksToEngineer} from "../../redux/crm/task/actions";
import {financeCurrencyGet} from "../../redux/finance/actions";
import ModalUpdateTask from "../../components/crm/task/ModalUpdateTask";
import ModalUpdateArticles from "../../containers/modal/ModalUpdateArticles/ModalUpdateArticles";
import {articlesActions} from "../../redux/articles/slice";
import ModalUpdate from "./ModalUpdate/ModalUpdate";
import Settings from "./Settings";
import {callsActions} from "../../redux/toolkit/calls/slice";
import {appActions} from "../../redux/toolkit/app/slice";

const Index = React.lazy(() =>
    import(/* webpackChunkName: "viwes-index" */ './index/index')
);
const SecondMenu = React.lazy(() =>
    import(/* webpackChunkName: "viwes-second-menu" */ './second-menu')
);
const BlankPage = React.lazy(() =>
    import(/* webpackChunkName: "viwes-blank-page" */ './blank-page')
);
const Profile = React.lazy(() =>
    import(/* webpackChunkName: "viwes-index" */ './profile')
);
const Statistics = React.lazy(() =>
    import(/* webpackChunkName: "viwes-index" */ './statistics')
);
const Crm = React.lazy(() =>
    import(/* webpackChunkName: "viwes-index" */ './crm')
);
const Partners = React.lazy(() =>
    import(/* webpackChunkName: "viwes-index" */ './partners')
);
const Updates = React.lazy(() =>
    import(/* webpackChunkName: "viwes-index" */ './updates/index')
);
const ArticleView = React.lazy(() =>
    import(/* webpackChunkName: "viwes-index" */ './updates/ArticleView/ArticleView')
);
const Calls = React.lazy(() =>
    import(/* webpackChunkName: "views-index" */ './calls')
);

const App = ({ match }) => {
    // eslint-disable-next-line no-unused-vars
    const {hash} = useLocation();
    // eslint-disable-next-line no-unused-vars
    const dispatch = useDispatch();
    const { connected } = useSelector((state) => state.websocket);
    // eslint-disable-next-line no-unused-vars
    const { newOrder, youResponsibleOrder, newTasks, newCall, callAbonentStatus, callSubscription, callUnsubscribe } = useSelector((state) => state.websocket.event);

    const scrollToAnchor = () => {

        if (hash) {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                const yOffset = -130;
                if (element) {
                    const position = element.getBoundingClientRect().top + yOffset;
                    window.scrollBy({top: position, behavior: 'smooth'})
                }
            }, 500);
        }
    };

    useEffect(() => {
        scrollToAnchor();
    }, [hash]);

    useEffect(() => {
        scrollToAnchor();
        dispatch(financeCurrencyGet());
    }, []);

    useEffect(() => {
        if (connected) {
            dispatch(send({type: 'getCountNewOrder'}));
            dispatch(send({type: 'getCountYouResponsibleOrder'}));
            dispatch(send({type: 'getCountNewTasks'}));
            dispatch(send({type: 'getCountOpenedTasks'}));
            dispatch(send({type: 'getCountTasksToEngineer'}));
            dispatch(send({type: 'getListCurrency'}));
        }
    }, [connected]);

    useEffect(() => {
        if (newOrder) {
            const orderId = Number.parseInt(newOrder.order_id, 10);
            const action = newOrder?.action;
            if (!orderId && action === 'countNewOrder') {
                dispatch(setCountNewOrder(Number.parseInt(newOrder.data, 10)));
            }
        }
    }, [newOrder]);

    useEffect(() => {
        if (youResponsibleOrder) {
            const orderId = Number.parseInt(youResponsibleOrder.order_id, 10);
            const action = youResponsibleOrder?.action;
            if (!orderId && action === 'countYouResponsibleOrder') {
                dispatch(setCountYouResponsibleOrder(Number.parseInt(youResponsibleOrder.data, 10)));
            }
        }
    }, [youResponsibleOrder]);

    useEffect(() => {
        if (newTasks) {
            const taskId = Number.parseInt(newTasks.task_id, 10);
            const action = newTasks?.action;
            if (!taskId && action === 'countNewTasks') {
                dispatch(setCountNewTasks(Number.parseInt(newTasks.data, 10)));
            }
            if (!taskId && action === 'countOpenedTasks') {
                dispatch(setCountOpenedTasks(Number.parseInt(newTasks.data, 10)));
            }
            if (!taskId && action === 'countTasksToEngineer') {
                dispatch(setCountTasksToEngineer(newTasks.data));
            }
        }
    }, [newTasks]);

    useEffect(() => {
        if (newCall) {
            dispatch(callsActions.setXsiEventsNewCall(newCall));
        }
    }, [newCall]);

    useEffect(() => {
        if (callAbonentStatus) {
            dispatch(callsActions.setXsiEventsAbonentStatus(callAbonentStatus));
        }
    }, [callAbonentStatus]);

    useEffect(() => {
        if (callSubscription) {
            dispatch(appActions.setNotification({type: 'success', message: 'Подписались на: ' + callSubscription.data?.targetId + ' || ' + callSubscription.data?.s_id }));
        }
    }, [callSubscription]);

    useEffect(() => {
        if (callUnsubscribe) {
            const sub_id = callUnsubscribe.data?.s_id;
            const info = callUnsubscribe.data?.targetId ? callUnsubscribe.data.targetId : 'Все звонки';
            dispatch(appActions.setNotification({type: 'error', message: 'Удалена подписка: ' + info + ' || ' + sub_id}));
            dispatch(callsActions.fetchXsiEventsGetSubscriptions());
        }
    }, [callUnsubscribe]);

    return (
        <AppLayout>
            <div className="dashboard-wrapper">
                <Suspense fallback={<div className="loading" />}>
                    <Switch>
                        <Route
                            path={`${match.url}/profile`}
                            render={(props) => <Profile {...props} />}
                        />
                        <ProtectedRoute
                            path={`${match.url}/calls`}
                            component={Calls}
                            permissions={['zvonk1728632218']}
                        />
                        <ProtectedRoute
                            path={`${match.url}/statistics`}
                            component={Statistics}
                            permissions={['pome1614226093', 'pozv1616256161']}
                        />
                        <ProtectedRoute
                            path={`${match.url}/crm`}
                            component={Crm}
                            permissions={['prosm1628105993', 'prosm1632215354', 'prosm1632466840']}
                        />
                        <ProtectedRoute
                            path={`${match.url}/partners`}
                            component={Partners}
                            permissions={['prosm1632466571', 'prosm1670835105']}
                        />
                        <ProtectedRoute
                            path={`${match.url}/settings`}
                            component={Settings}
                            permissions={['crms1629778467']}
                        />
                        <Route
                            path={`${match.url}/second-menu`}
                            render={(props) => <SecondMenu {...props} />}
                        />
                        <Route
                            path={`${match.url}/blank-page`}
                            render={(props) => <BlankPage {...props} />}
                        />
                        <Route
                            path={`${match.url}/updates/:slug`}
                            render={(props) => <ArticleView {...props} />}
                        />
                        <Route
                            path={`${match.url}/updates`}
                            render={(props) => <Updates {...props} />}
                        />
                        <Route
                            path={`${match.url}`}
                            render={(props) => <Index {...props} />}
                        />
                        <Redirect to="/error" />
                    </Switch>
                </Suspense>
                <ModalUpdate/>
            </div>
            <ModalUpdateTask />
        </AppLayout>
    );
};

const mapStateToProps = ({ menu }) => {
    const { containerClassnames } = menu;
    return { containerClassnames };
};

export default withRouter(connect(mapStateToProps, {})(App));
