import React from 'react';

const CallsIcon = ({width= "23", height= "23"}) => (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className='hoverStroke'
              d="M5.8 13.2C2.88182 10.2818 1.54064 7.59556 1.13729 6.6827C1.04166 6.46627 1 6.23182 1 5.9952V4.0049C1 3.47447 1.21071 2.96576 1.58579 2.59068L2.76226 1.41421C3.54331 0.633165 4.80964 0.633165 5.59068 1.41421L7.25239 3.07592C7.92652 3.75005 8.0311 4.80629 7.50227 5.59953L6.93793 6.44605C6.53981 7.04322 6.48933 7.80931 6.85237 8.42843C7.25801 9.12018 7.83489 10.0114 8.41177 10.5882C8.98864 11.1651 9.87982 11.742 10.5716 12.1476C11.1907 12.5107 11.9568 12.4602 12.554 12.0621L13.4005 11.4977C14.1937 10.9689 15.25 11.0735 15.9241 11.7476L17.5858 13.4093C18.3668 14.1904 18.3668 15.4567 17.5858 16.2377L16.4093 17.4142C16.0342 17.7893 15.5255 18 14.9951 18H13.0048C12.7682 18 12.5337 17.9583 12.3173 17.8627C11.4044 17.4594 8.71818 16.1182 5.8 13.2Z"
              stroke="#3A3A3A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default CallsIcon;