import React from 'react'
import {Select, MenuItem} from '@mui/material'
import css from './SelectMui.module.scss'
import DropdownIcon from "../../../assets/icons/dropdown-icon";

const SelectMui = ({text, elem, activeElem, onChange, disabled, includes, disableEmpty = true}) => {

    // eslint-disable-next-line no-shadow
    const getItemById = (id, elem) => {
        return elem.find((el) => el.id === id)
    }

    const handleChange = (event) => {
        onChange(getItemById(event.target.value, elem))
    }

    return (
        <div className={css.select}>
            <p className={css.selectHeadline}>{text}</p>
            <Select
                disabled={disabled}
                className={css.selectElem}
                onChange={handleChange}
                value={activeElem || -1}
                label="Выберите"
                IconComponent={({ className }) => {
                    // eslint-disable-next-line no-param-reassign
                    className = className.replace("MuiSelect-iconOpen", "")
                    return <DropdownIcon className={className} />
                }}
                MenuProps={{
                    disableScrollLock: true,
                }}
            >
                <MenuItem
                    key="-1"
                    value="-1"
                    disabled={disableEmpty}
                >
                    <em>- Не выбрано -</em>
                </MenuItem>
                {includes ?
                    elem.map(el => {
                        return <MenuItem
                            key={el.id}
                            disabled={!includes.includes(el.id)}
                            value={el.id}
                        >{el.name}</MenuItem>
                    })
                    :
                    elem.map(el => {
                        return <MenuItem
                            key={el.id}
                            value={el.id}
                        >{el.name}</MenuItem>
                    })
                }
            </Select>
        </div>
    )
}

export default SelectMui