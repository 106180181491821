import {statusFinishTasks} from "../../../../../constants/defaultValues";
import {fetchProcessStatus} from "../../../../../api/query/processHandbook";
import {getProcessFiles, removeContact, updateProcess as updateProcessQuery} from "../../../../../api/query/process";


export const getProcessStatuses = async (data) => {
    let result = {
        type: '',
        result: null,
    }
    await fetchProcessStatus(data)
        .then(response => {
            result = {
                type: 'success',
                result: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
};

export const collectionMembers = ({process, tasks = []}) => {
    const result = [];
    if (!process) return result;
    const creator = process.user ?? null;
    const manager = process.manager ?? null;
    const managerGroup = process.managerGroup ?? null;
    const tasksItems = tasks;

    if (creator && creator.crmGroups?.length) {
        result.push({
            id: creator.id,
            name: `${creator.last_name} ${creator.first_name}`,
            avatar: creator.avatarData ?? null,
            roles: creator.crmGroups?.map(x => x.role) ?? [],
            isResponsible: false,
        })
    }

    if (manager) {
        result.push({
            id: manager.id,
            name: `${manager.last_name} ${manager.first_name}`,
            avatar: manager.avatarData ?? null,
            roles: [],
            isResponsible: true,
        })
    }

    if (managerGroup) {
        result.push({
            id: managerGroup.id,
            name: managerGroup.name,
            avatar: null,
            roles: [],
            isResponsible: true,
        })
    }

    tasksItems.forEach(item => {
        const taskManager = item.manager;
        if (!taskManager) return;
        const existsUser = result.findIndex(x => x.id === taskManager.id);
        if (existsUser > -1) return;
        result.push({
            id: taskManager.id,
            name: `${taskManager.last_name} ${taskManager.first_name}`,
            avatar: taskManager.avatarData ?? null,
            roles: taskManager.crmGroups?.map(x => x.role) ?? [],
            isResponsible: false,
        })
    });

    return result;
}

export const updateProcess = async (data) => {
    let result = {
        type: '',
        result: null,
    }
    await updateProcessQuery(data)
        .then(response => {
            result = {
                type: 'success',
                result: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
};

export const getFiles = async (data) => {
    let result = {
        type: '',
        result: null,
    }
    await getProcessFiles(data)
        .then(response => {
            result = {
                type: 'success',
                result: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                result: message,
            };
        });
    return result;
};

export const removeContactQuery = async (data) => {
    let result = {
        type: '',
        result: null,
    }
    await removeContact(data)
        .then(response => {
            result = {
                type: 'success',
                result: response.data,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                result: message,
            };
        });
    return result;
};