import React from "react";
import {useMemo, useState} from "react";
import ModalCustomQuery from "../../../../../../../../containers/modal/ModalCustomQuery";
import {getProcessStatuses, updateProcess} from "../../../helpers";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import SelectMui from "../../../../../../../../components/ui/SelectMui/SelectMui";
import {processActions} from "../../../../../../../../redux/toolkit/process/slice";
import {appActions} from "../../../../../../../../redux/toolkit/app/slice";

const defaultProcessData = {
    id: null
}

const CancelProcess = () => {
    const [modalShowQuestion, setModalShowQuestion] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [status, setStatus] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const processEvent = useSelector((state) => state.websocket.event.process);
    const [processData, setProcessData] = useState(defaultProcessData);
    const dispatch = useDispatch();

    const changeShowQuestion = (status) => {
        setModalShowQuestion(status);
    }

    const changeShow = (status) => {
        setModalShow(status);
    }

    const isDisabledSubmit = useMemo(() => {
        return !status || submitting
    }, [status, submitting]);

    const onSubmit = async () => {
        const result = await updateProcess({
            id: processData.id,
            status_id: 7,
            double_status_id: status?.id,
        });
        setSubmitting(true);
        const {type: resultType, data: resultData} = result;
        setSubmitting(false);
        if (resultType === 'success') {
            changeShow(false);
            dispatch(processActions.reloadTasks(true));
            dispatch(appActions.setNotification({type: 'success', message: 'Сохранено'}));
        } else {
            dispatch(appActions.setNotification({type: 'error', message: resultData}));
        }
    };

    const content = useMemo(() => {
        return (
            <div className="ml-auto mr-auto" style={{width: '240px'}}>
                <SelectMui
                    text="Причина"
                    activeElem={status?.id}
                    elem={statuses}
                    onChange={setStatus}
                />
            </div>
        )
    }, [statuses, status]);

    const getStatusList = async () => {
        const {type, result} = await getProcessStatuses({parent_id: 7});
        if (type === 'success') {
            setStatuses(result);
            return true;
        }
        setStatuses([]);
    }

    useEffect(() => {
        getStatusList();
    }, []);

    useEffect(() => {
        const action = processEvent?.action;
        const processId = processEvent?.process_id;
        if (action === 'taskIsFailure') {
            setProcessData({id: processId});
            setModalShowQuestion(true);
        }
    }, [processEvent]);


    return (
        <>
            <ModalCustomQuery
                title="Вы хотите закрыть процесс?"
                content={""}
                isShow={modalShowQuestion}
                changeShow={changeShowQuestion}
                onSubmit={{
                    text: "Да",
                    callback: () => {
                        changeShowQuestion(false);
                        setTimeout(() => {
                            setModalShow(true);
                        }, 500);
                    },
                    submitting: false,
                    disabled: false,
                }}
                onCancel={{
                    text: "Нет",
                    callback: () => changeShowQuestion(false)
                }}
            />
            <ModalCustomQuery
                title="Укажите причину отмены процесса"
                content={content}
                isShow={modalShow}
                changeShow={changeShow}
                onSubmit={{
                    text: "Сохранить",
                    callback: onSubmit,
                    submitting: submitting,
                    disabled: isDisabledSubmit,
                }}
            />
        </>
    )
}
export default CancelProcess;