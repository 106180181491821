import React, {useEffect, useRef, useState} from 'react';

import css from '../XsiForm.module.scss';


const XsiFormCallListEmpty = props => {
    return <div className={css.emptyList}>
        Нет звонков
    </div>
}
export default XsiFormCallListEmpty;