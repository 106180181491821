import {deleteTaskWatcher, taskChange, taskCreate} from "../../../api/query/task";
import ErrorResponse from "../../../components/common/ErrorResponse";

export const deleteWatcherQuery = async (params) => {
    let result = false;
    await deleteTaskWatcher(params)
        .then(() => {
            result = true;
        })
        .catch((error) => {
            ErrorResponse(error.response);
        });
    return result;
}

export const changeTask = async (params) => {
    let result = {
        type: '',
        data: null,
    }
    await taskChange(params)
        .then(response => {
            result = {
                type: 'success',
                data: response,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}

export const createTask = async (params) => {
    let result = {
        type: '',
        data: null,
    }
    await taskCreate(params)
        .then(response => {
            result = {
                type: 'success',
                data: response,
            }
        }).catch(error => {
            const message = error?.response?.data?.message ?? 'Что-то пошло не так';
            result = {
                type: 'error',
                data: message,
            };
        });
    return result;
}