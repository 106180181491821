import React from 'react';

import css from '../XsiForm.module.scss';


const XsiFormSubscriptionEmpty = props => {
    return <div className={css.emptyList}>
        Нет подписок
    </div>
}
export default XsiFormSubscriptionEmpty;