import React from 'react';
import {useSelector} from "react-redux";
import {callsSelectors} from "../../../../../redux/toolkit/calls/selectors";
import XsiFormCallListItem from "./XsiFormCallListItem";
import XsiFormCallListClearBtn from "./XsiFormCallListClearBtn";
import XsiFormCallListEmpty from "./XsiFormCallListEmpty";

import css from '../XsiForm.module.scss';

const XsiFormCallList = props => {
    const {calls} = useSelector(callsSelectors.callsXsiEvents);

    return (
        <div>
            <XsiFormCallListClearBtn/>
        <div className={css.callListWrapper}>
            {calls.length > 0
                ? (<>
                    <div className={`${css.callList} `}>
                        {
                            calls.map(item => <XsiFormCallListItem key={item.call_id} data={item}/>)
                        }
                    </div>
                </>)
                : (<XsiFormCallListEmpty/>)
            }
        </div>
        </div>
    )
}
export default XsiFormCallList;