import React, {useContext, useEffect, useRef, useState} from 'react'
import {Scrollbars} from 'react-custom-scrollbars'
import {useSelector} from 'react-redux'
import CommentsItem from '../CommentsItem/CommentsItem'
import ChatToggle from '../ChatTypes/ChatToggle/ChatToggle'
import GoBottomButton from './GoBottomButton'
import LoaderOverlay from './LoaderOverlay'
import {DesktopContext} from '../../../../utils/hoc/withContext'
import css from '../Comments.module.scss'

const Messages = props => {
    const {
        comments,
        scrollBar,
        sendToUser,
        types: chatTypes,
        activeChat,
        setActiveChat,
        changeChat,
        setActiveStyle,
        activeStyle,
        executionQuery,
        fetchPrevious,
    } = props

    const currentUserId = Number(useSelector(state => state.authUser.currentUser.id))

    const loadMoreRef = useRef(null)
    const desktop = useContext(DesktopContext)
    const [showGoBottom, setShowGoBottom] = useState(false)

    const handleScroll = ({scrollTop, clientHeight, scrollHeight}) =>
        setShowGoBottom(scrollHeight - scrollTop > 2 * clientHeight)

    const goBottom = () => scrollBar.current?.scrollToBottom()

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => entry.isIntersecting && fetchPrevious())
        if (loadMoreRef.current) observer.observe(loadMoreRef.current)
        return () => loadMoreRef.current && observer.unobserve(loadMoreRef.current)
    }, [fetchPrevious])

    return (
        <>
            <div className={`${css.chatList} ${css.chatListMobile}`}>
                {!desktop && changeChat && (
                    <ChatToggle {...{setActiveStyle, chatTypes, activeChat, activeStyle, changeChat, setActiveChat}} />
                )}
            </div>
            <Scrollbars ref={scrollBar} loader={executionQuery} onScrollFrame={handleScroll}>
                <div ref={loadMoreRef} />
                {comments.map(item => (
                    <CommentsItem
                        key={item.id}
                        {...item}
                        sendTo={sendToUser}
                        myMessage={currentUserId === item.user_id}
                    />
                ))}
            </Scrollbars>
            {executionQuery && <LoaderOverlay />}
            <GoBottomButton show={showGoBottom} onClick={goBottom} />
        </>
    )
}
export default Messages
