import React from 'react';
import {useSelector} from "react-redux";
import {callsSelectors} from "../../../../../redux/toolkit/calls/selectors";
import XsiFormAbonentListItem from "./XsiFormAbonentListItem";

import css from '../XsiForm.module.scss';

const XsiFormAbonentList = props => {
    const {abonents} = useSelector(callsSelectors.callsXsiEvents);

    const EmptyList = () => {
        return <div className={css.emptyList}>
            Нет абонентов
        </div>
    }

    return (<>
        {abonents.length > 0 ?
            <div className={css.xsiAbonentsList}>
                {abonents.map(item => <XsiFormAbonentListItem key={item.a_id} data={item}/>)}
            </div>
            : <EmptyList/>
        }
    </>)
}
export default XsiFormAbonentList;