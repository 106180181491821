import React, {useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {DialogContent, DialogTitle} from "@mui/material";
import {taskParamsExpand, taskParamsFields, taskStateDefault} from "./params";
import Header from "./Header";
import Content from "./Content";
import {taskActions} from "../../../redux/toolkit/task/slice";
import {taskSelectors} from "../../../redux/toolkit/task/selectors";
import {extractParams, getUserGroups, prepareDataForSubmit, prepareTaskData} from "./helper";
import {Loader1} from "../../../components/svg/Loader";
import {changeTask, createTask} from "./api";
import {StyledDialog} from "../../../utils/mui/dialog";
import {colorTask} from "../../../constants/entityColor";
import {appActions} from "../../../redux/toolkit/app/slice";
import {getParentEntity} from "../../../components/crm/task/helpers";

export const ModalCreateTaskContext = React.createContext();

const ModalCreateTask = ({
    isShow,
    changeShow,
 }) => {
    const dispatch = useDispatch();
    const {status: statusNextTask} = useSelector(taskSelectors.modalNextTask);
    const {loading: taskLoading, data: taskData} = useSelector(taskSelectors.taskCard); // данные по задаче, которая существует
    const {data: modalData} = useSelector(taskSelectors.modalCreate); // параметры, переданные в окно создания задачи

    const {taskId, typeDefault, orderID, clientData, changeClient, callbackAfterSave, callbackClose, parent_id, parentEntity, callbackBeforeSave} = useMemo(() => { // извлекаем параметры
        return extractParams(modalData);
    }, [modalData]);

    const { currentUser } = useSelector((state) => state.authUser);
    const [data, setData] = useState(taskStateDefault);
    const [firstData, setFirstData] = useState(taskStateDefault); // данные до их изменения (для определения изменилось ли значение какого-то из полей)
    const [savingData, setSavingData] = useState(false);


    const parentEntityData = useMemo(() => {
        let result = parentEntity;
        if (!result && data) {
            result = getParentEntity(data);
        }
        return result;
    }, [parentEntity, data]);


    const modal = useRef()

    const onClose = () => {
        changeShow(false);
        callbackClose();
    }

    const afterSave = () => {
        onClose(false);
        if (statusNextTask) {
            dispatch(taskActions.setStatusModalNextTask(false));
        }
        callbackAfterSave();
    }

    const disabledSubmit = useMemo(() => {
        const result = false;
        if (savingData) return true;
        if (taskLoading) return true;

        return result;
    }, [savingData, taskLoading]);

    const setDataHandler = (name, value) => {
        setData((oldValue) => {
            return {
                ...oldValue,
                [name]: value,
            }
        })
    }

    const submitForm = async () => {
        const prepareData = prepareDataForSubmit(data);
        const continueSaving = await callbackBeforeSave({...prepareData, id: data.id}, {
            data,
            onClose,
        });
        if (!continueSaving) {
            return false;
        }
        let query = null;
        setSavingData(true);
        if (data.id) {
            query = await changeTask({ id: data.id, params: prepareData });
        } else {
            query = await createTask(prepareData)
        }
        const {type: resultType, data: resultData} = query;
        setSavingData(false);
        if (resultType === 'error') {
            dispatch(appActions.setNotification({type: 'error', message: resultData}));
            return true;
        }
        if (!resultData) return false;
        afterSave();
        return true;
    }

    const modelParamsDefault = () => {
        const newData = {
            ...taskStateDefault,
            dateStart: new Date(),
            responsible: currentUser,
            client: clientData,
            order: orderID,
            parent_id,
            parent_entity: parentEntity,
        };
        if (typeDefault === 6) {
            newData.taskType = { id: 6, name: 'Поставщик' };
        }
        if (typeDefault === 11) {
            newData.taskType = { id: 11, name: 'Цель' };
        }
        // if (!clientData) {
        //     newData.taskType = null;
        // }
        setData(newData);
        setFirstData(newData);
    };

    const getTaskData = () => {
        const params = {
            id: taskId,
            params: {
                expand: taskParamsExpand.join(','),
                fields: taskParamsFields.join(',')
            }
        }
        dispatch(taskActions.fetchTask(params));
    }

    const setTaskData = (newData) => {
        if (!newData) return false;
        const preparedData = prepareTaskData(newData);
        setData(preparedData);
        setFirstData(preparedData);
        return true;
    };

    useEffect(() => {
        if (taskId) {
            getTaskData();
        } else {
            modelParamsDefault();
        }
    }, [taskId]);

    useEffect(() => {
        setTaskData(taskData);
    }, [taskData]);

    useEffect(() => {
        dispatch(taskActions.fetchTaskTypes());
        return () => {
            dispatch(taskActions.setDataModalCreate(null));
            dispatch(taskActions.setTask(null));
            dispatch(taskActions.setTaskTypes([]));
        }
    }, []);

    return (
        <>
            <StyledDialog
                open={isShow}
                maxWidth="md"
                onClose={onClose}
                className="modal-dialog-mui"
                sx={{
                    '.MuiPaper-root': {
                        border: `1px solid ${colorTask}`
                    }
                }}
                ref={modal}
            >
                <DialogTitle>
                    <Header data={data} onClose={onClose} />
                </DialogTitle>
                <DialogContent>
                    {taskLoading ? (
                        <div style={{height: '300px'}} className="d-flex align-items-center justify-content-center">
                            <Loader1 />
                        </div>
                    ) : (
                        <Content
                            data={data}
                            setDataHandler={setDataHandler}
                            modalState={isShow}
                            onClose={onClose}
                            changeClient={changeClient}
                            submitForm={submitForm}
                            disabledSubmit={disabledSubmit}
                            parentEntity={parentEntityData}
                            modalRef={modal}
                            firstData={firstData}
                        />
                    )}
                </DialogContent>
            </StyledDialog>
        </>
    );
};
export default ModalCreateTask;
